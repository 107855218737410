import { AnchorHTMLAttributes, DetailedHTMLProps, ReactNode } from "react";

import "./index.scss";

export const CustomA = ({ kind, className, children, preIcon, postIcon, ...props }: { kind?: "primary" | "secondary" | "outline" | "text", preIcon?: ReactNode, postIcon?: ReactNode } & DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) => {
  if (!kind) kind = "primary";
  
  return <a {...props} className={`CustomA ${className || ""} kind-${kind}`}>
    {preIcon && <span className="pre-icon">{preIcon}</span>}
    {children && <span className="text">{children}</span>}
    {postIcon && <span className="post-icon">{postIcon}</span>}
  </a>;
};
