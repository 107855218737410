import { LanguageContext } from "../../../utils/multiLanguageString";
import { CustomButton } from "../../../components/CustomAButton/CustomButton";

import { MomentProps } from "..";
import { ArrowSvg } from "../../../components/ArrowSvg";
import { useContext } from "react";

export const PartialQuestItemMoment = ({ moment, goForward, visible }: MomentProps) => {
  if (moment.type !== "partialQuestItem") return <></>;

  const { multiLanguageString, getString } = useContext(LanguageContext);
  
  return <div className={`Moment ${`type-${moment.type}`} ${visible ? "visible" : "invisible"}`}><div className="total-wrapper">
    
    <div className="bottom-wrapper">
      <div className="text-wrapper">
        <div className="little-text-wrapper">
          {multiLanguageString(getString("momentPartialObtained"))}
        </div>
        <div className="title-wrapper">
          {multiLanguageString(moment.name)}
        </div>
      </div>
      <div className="image-wrapper">
        <img src={multiLanguageString(moment.image)}  alt="" />
      </div>
      <div className="actions-wrapper">
        <CustomButton postIcon={<ArrowSvg />} onClick={() => {
          goForward();
        }}>
          {multiLanguageString(getString("momentContinue"))}
        </CustomButton>
      </div>
    </div>
  </div>
  </div>;
};
