import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { LanguageContext } from "../../utils/multiLanguageString";
import { CustomA } from "../CustomAButton/CustomA";

import black169 from "./black169.jpg";
import { z } from "zod";

import { ReactComponent as PlaySvg } from "./play.svg";
import { ReactComponent as PauseSvg } from "./pause.svg";
import { ReactComponent as MuteSvg } from "./mute.svg";
import { ReactComponent as UnmuteSvg } from "./unmute.svg";

import "./index.scss";
import { ReactNode, useContext, useEffect } from "react";
import { mediaSchema } from "../../../../backend/types/media";
import ga4 from "react-ga4";

type MediaType = z.infer<typeof mediaSchema>;

export const MediaRender = ({ media, outsideExperience, context, buttonElement, buttonOnClick }: { media: MediaType | undefined, outsideExperience?: boolean, context: "experience" | "collection" | "pinpoints", buttonElement: (buttonOnClick: React.MouseEventHandler<HTMLButtonElement>) => ReactNode, buttonOnClick: React.MouseEventHandler<HTMLButtonElement> }) => {
  useEffect(() => {
    const orientationHandler = ({ currentTarget }: Event) => {
      const target = currentTarget as ScreenOrientation;
      const currentVideo = document.querySelector(".swiper-slide-active video");
      
      (async () => {
        if (target.type.includes("landscape")) {
          if (currentVideo) {
            try {
              await currentVideo.requestFullscreen();
            } catch (error) {
  
            }
          }
        } else {
          if (currentVideo && document.fullscreenElement) {
            try {
              document.exitFullscreen();
            } catch (error) {
              
            }
          }
        }
      })();
    };

    const legacyOrientationHandler = () => {
      if (!window.orientation) return;

      const landscape = window.orientation === 90 || window.orientation === -90;
      const currentVideo = document.querySelector(".swiper-slide-active video");
      
      (async () => {
        if (landscape) {
          if (currentVideo) {
            try {
              await currentVideo.requestFullscreen();
            } catch (error) {
  
            }
          }
        } else {
          if (currentVideo && document.fullscreenElement) {
            try {
              document.exitFullscreen();
            } catch (error) {
              
            }
          }
        }
      })();
    };

    if (screen.orientation) {
      screen.orientation.addEventListener("change", orientationHandler);
    } else {
      window.addEventListener("orientationchange", legacyOrientationHandler);
    }

    return () => {if (screen.orientation) screen.orientation.removeEventListener("change", orientationHandler);};
  }, []);

  const { multiLanguageString, getString } = useContext(LanguageContext);
  
  return media ? <><div className="Media">
    <div className="title-wrapper">{multiLanguageString(media.title)}</div>
    <Swiper 
      modules={[Pagination]} 
      pagination={{ clickable: true }}
      onInit={(s) => {
        ga4.event("media_open_slide", {
          slide_number: s.activeIndex + 1,
          media: media.analyticsName,
          context,
        });
      }} 
      onSlideChange={(s) => {
        ga4.event("media_open_slide", {
          slide_number: s.activeIndex + 1,
          media: media.analyticsName,
          context,
        });
      }}
    >
      {media.slides.map((slide, index) => (
        <SwiperSlide key={"sl" + index}>
          <div className="slide">
    
            {slide.type === "video"
          && <div className="video-wrapper">
            <video 
              autoPlay
              onClick={(event) => {
                const video = event.target as HTMLVideoElement;
                
                if (video.paused) {
                  video.play();
                } else {
                  video.pause();
                }
              }}
              onLoad={(event) => {
                const video = event.target as HTMLVideoElement;

                const muteButton = video.parentElement?.querySelector(".mute");
                const unmuteButton = video.parentElement?.querySelector(".unmute");

                if (video.muted && muteButton && unmuteButton) {
                  (muteButton as HTMLButtonElement).style.display = "none";
                  (unmuteButton as HTMLButtonElement).style.display = "inline";
                } else if (!video.muted && muteButton && unmuteButton) {
                  (muteButton as HTMLButtonElement).style.display = "inline";
                  (unmuteButton as HTMLButtonElement).style.display = "none";
                }
              }}
              onTimeUpdate={(event) => {
                const video = event.target as HTMLVideoElement;
                const duration = video.duration;
                const timestamp = video.currentTime;
                const ratio = timestamp / duration;
                const innerProgressBar = video.parentElement?.querySelector(".progressBar span");

                if (innerProgressBar) {
                  (innerProgressBar as HTMLSpanElement).style.width = `${ratio * 100}%`;
                }

                const muteButton = video.parentElement?.querySelector(".mute");
                const unmuteButton = video.parentElement?.querySelector(".unmute");

                if (video.muted && muteButton && unmuteButton) {
                  (muteButton as HTMLButtonElement).style.display = "none";
                  (unmuteButton as HTMLButtonElement).style.display = "inline";
                } else if (!video.muted && muteButton && unmuteButton) {
                  (muteButton as HTMLButtonElement).style.display = "inline";
                  (unmuteButton as HTMLButtonElement).style.display = "none";
                }
              }}
              onPlay={(event) => {
                const video = event.target as HTMLVideoElement;
                const playButton = video.parentElement?.querySelector(".play");
                const pauseButton = video.parentElement?.querySelector(".pause");
                const muteButton = video.parentElement?.querySelector(".mute");
                const unmuteButton = video.parentElement?.querySelector(".unmute");
                (playButton as HTMLButtonElement).style.display = "none";
                (pauseButton as HTMLButtonElement).style.display = "inline";

                if (video.muted && muteButton && unmuteButton) {
                  (muteButton as HTMLButtonElement).style.display = "none";
                  (unmuteButton as HTMLButtonElement).style.display = "inline";
                } else if (!video.muted && muteButton && unmuteButton) {
                  (muteButton as HTMLButtonElement).style.display = "inline";
                  (unmuteButton as HTMLButtonElement).style.display = "none";
                }
              }}
              onPause={(event) => {
                const video = event.target as HTMLVideoElement;
                const playButton = video.parentElement?.querySelector(".play");
                const pauseButton = video.parentElement?.querySelector(".pause");
                const muteButton = video.parentElement?.querySelector(".mute");
                const unmuteButton = video.parentElement?.querySelector(".unmute");
                (playButton as HTMLButtonElement).style.display = "inline";
                (pauseButton as HTMLButtonElement).style.display = "none";

                if (video.muted && muteButton && unmuteButton) {
                  (muteButton as HTMLButtonElement).style.display = "none";
                  (unmuteButton as HTMLButtonElement).style.display = "inline";
                } else if (!video.muted && muteButton && unmuteButton) {
                  (muteButton as HTMLButtonElement).style.display = "inline";
                  (unmuteButton as HTMLButtonElement).style.display = "none";
                }
              }}
              onContextMenu={(event) => {event.preventDefault();}}
              poster={black169}
              playsInline
            >
              <source src={`${process.env.REACT_APP_BASE_MEDIA_URL}${multiLanguageString(slide.fileUrl)}`} type="video/mp4"></source>
            </video>
            <div className="controls">
              <div className="timeIndicator">
                <div className="progressBar">
                  <span></span>
                </div>
              </div>
              <div className="buttons">
                <button className="play" title={multiLanguageString(getString("mediaPlay"))} onClick={(event) => {
                  const video = event.currentTarget.parentElement?.parentElement?.parentElement?.querySelector("video");
                  if (video) {
                    video.play();
                  }
                }}><PlaySvg /></button>
                <button className="pause" style={{ display: "none" }} title={multiLanguageString(getString("mediaPause"))} onClick={(event) => {
                  const video = event.currentTarget.parentElement?.parentElement?.parentElement?.querySelector("video");
                  if (video) {
                    video.pause();
                  }
                }}><PauseSvg /></button>
                <button className="mute" title={multiLanguageString(getString("mediaMute"))} onClick={(event) => {
                  const video = event.currentTarget.parentElement?.parentElement?.parentElement?.querySelector("video");
                  const thisButton = event.currentTarget;
                  const unmuteButton = event.currentTarget.parentElement?.parentElement?.parentElement?.querySelector(".unmute");
                  
                  if (video) {
                    video.muted = true;
                  }
                  if (unmuteButton) {
                    (thisButton as HTMLButtonElement).style.display = "none";
                    (unmuteButton as HTMLButtonElement).style.display = "inline";
                  }
                }}><UnmuteSvg /></button>
                <button className="unmute" style={{ display: "none" }} title={multiLanguageString(getString("mediaUnmute"))} onClick={(event) => {
                  const video = event.currentTarget.parentElement?.parentElement?.parentElement?.querySelector("video");
                  const thisButton = event.currentTarget;
                  const muteButton = event.currentTarget.parentElement?.parentElement?.parentElement?.querySelector(".mute");
                  if (video) {
                    video.muted = false;
                  }
                  if (muteButton) {
                    (thisButton as HTMLButtonElement).style.display = "none";
                    (muteButton as HTMLButtonElement).style.display = "inline";
                  }
                }}><MuteSvg /></button>
              </div>
            </div>
            {slide.description && <div className="description">{multiLanguageString(slide.description)}</div>}
          </div>}
            {slide.type === "image"
          && <div className="image-wrapper">
            <img alt="" src={`${process.env.REACT_APP_BASE_MEDIA_URL}${multiLanguageString(slide.fileUrl)}`} />
            {slide.description && <div className="description">{multiLanguageString(slide.description)}</div>}
          </div>}
            {slide.type === "audio"
          && <div className="audio-wrapper">
            {slide.preview && <div className="image-wrapper" style={{ marginBottom: "10px" }}>
              <img alt="" src={`${process.env.REACT_APP_BASE_MEDIA_URL}${multiLanguageString(slide.preview)}`} />
            </div>}
            <audio autoPlay controls playsInline>
              <source src={`${process.env.REACT_APP_BASE_MEDIA_URL}${multiLanguageString(slide.fileUrl)}`} type="audio/mp3"></source>
            </audio>
            {slide.description && <div className="description">{multiLanguageString(slide.description)}</div>}
          </div>}
            {slide.type !== "video" && slide.type !== "image" && slide.type !== "audio"
          && <div className="file-wrapper">
            {slide.preview && <div className="image-wrapper">
              <img alt="" src={`${process.env.REACT_APP_BASE_MEDIA_URL}${multiLanguageString(slide.preview)}`} />
            </div>}
            <CustomA href={`${process.env.REACT_APP_BASE_MEDIA_URL}${multiLanguageString(slide.fileUrl)}`} target="_blank">
              {multiLanguageString(getString("momentSeeFile"))}
            </CustomA>
            {slide.description && <div className="description">{multiLanguageString(slide.description)}</div>}
          </div>}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
    {media.relatedLinks && (media?.relatedLinks || []).length > 0 && <div className="related-wrapper">
      <div className="related-list-wrapper">
        {media.relatedLinks?.map((singleLink, index) => <div key={"ad" + index} >
          {(!outsideExperience || !singleLink.hideOutsideExperience) && <div className="single-link-wrapper">
            <a target="_blank" href={multiLanguageString(singleLink.url).includes("http") ? multiLanguageString(singleLink.url) : `${process.env.REACT_APP_BASE_MEDIA_URL as string}${multiLanguageString(singleLink.url)}`}>{multiLanguageString(singleLink.text)}</a>
          </div>}
        </div>)}
      </div>
    </div>}
  </div>
  {buttonElement((event: React.MouseEvent<HTMLButtonElement>) => {
    const videoElements = document.querySelectorAll(".Media video");
    videoElements.forEach((videoElement)  => {
      const duration = (videoElement as HTMLVideoElement).duration;
      const timestamp = (videoElement as HTMLVideoElement).currentTime;
      const ratio = timestamp / duration;
      ga4.event("media_video_watched", {
        media: media.analyticsName,
        context,
        completion: Math.round((ratio * 100)),
      });
    });
    buttonOnClick(event);
  })}
  </> : null;
};
