import { DetailedHTMLProps, InputHTMLAttributes, ReactNode, useState } from "react";
import "./index.scss";
import { ReactComponent as Check } from "./check.svg";

export const Checkbox = ({ label, labelColor, textColor, activeColor, type, onChange, error, ...props }: { label?: ReactNode, labelColor?: string, textColor?: string, activeColor?: string, error?: string, } & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {
  const [focused, setFocused] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(props.checked || false);

  return <div className="Checkbox">
    <label>
      {(label || error) && <span className="labels">
        {error && <span className="error">{error}</span>}
        <span className="label" style={{ color: textColor }}>{label}</span>
      </span>}
      <span className="control">
        <input 
          type="checkbox"
          onFocus={() => {setFocused(true);}}
          onBlur={() => {setFocused(false);}}
          onChange={(event) => {setChecked(event.target.checked);if (onChange) onChange(event);}}
          {...props}
        />
        <span className="controlItem" style={{ backgroundColor: checked ? labelColor : "white", color: labelColor, outlineColor: focused ? activeColor : "transparent", boxShadow: focused ? `0px 0px 6px ${activeColor}` : "none" }}>
          <div className="symbol" style={{ opacity: checked ? 1 : 0 }}><Check /></div>
        </span>
      </span>
    </label>
  </div>;
};
