import { LanguageContext } from "../../../utils/multiLanguageString";
import { CustomButton } from "../../../components/CustomAButton/CustomButton";

import { MomentProps } from "..";
import { useContext, useEffect } from "react";
import { ArrowSvg } from "../../../components/ArrowSvg";
import { makeRemoteCallHandler } from "../../../libProcedureWire";
import { Endpoints } from "../../../../../backend";
import { skinParameters } from "../../../utils/skins/skinAssets";
import ga4 from "react-ga4";

export const SkinGivenMoment = ({ moment, goForward, visible, characters }: MomentProps) => {
  if (moment.type !== "skinGiven") return <></>;
  const character = characters.find((singleCharacter) => singleCharacter._id === moment.characterId);
  //@ts-expect-error
  const skin = skinParameters[moment.skin];

  const remoteCall = makeRemoteCallHandler<Endpoints>();

  useEffect(() => {
    ga4.event("skin_given", {
      skin_reason: "station",
      skin_name: moment.skin,
    });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const currentCollectionSkins = (await remoteCall("collectionRead", {})).skins;
        const newCollectionSkins = currentCollectionSkins.includes(moment.skin) ? currentCollectionSkins : [...currentCollectionSkins, moment.skin];
        await remoteCall("collectionSave", { skins: newCollectionSkins });
      } catch (e) {

      }
    })();
  }, []);

  const { multiLanguageString, getString } = useContext(LanguageContext);
  
  return <div className={`Moment ${`type-${moment.type}`} ${visible ? "visible" : "invisible"}`}><div className="total-wrapper">
    
    {character && moment.dialogueText && <div className="balloon-wrapper">
      {character && <div className="name">{multiLanguageString(character.name)}</div>}
      {moment.dialogueText && <div className="text" dangerouslySetInnerHTML={{ __html: multiLanguageString(moment.dialogueText) }} />}
    </div>}
    <div className="bottom-wrapper">
      <>
        <div className="image-wrapper">
          {skin.image && <img src={skin.image}  alt="" />}
        </div>
        <div className="text-wrapper">
          <div className="title-wrapper">
            {multiLanguageString(skin.name)}
          </div>
          <div className="description-wrapper">
            {multiLanguageString(getString("momentSkinAdded"))}
          </div>
          <div className="description-wrapper suggestion-wrapper">
            {multiLanguageString(getString("momentSkinSuggestion"))}
          </div>
        </div>
      </>
      <div className="actions-wrapper">
        <CustomButton postIcon={<ArrowSvg />} onClick={() => {
          goForward();
        }}>
          {multiLanguageString(getString("momentContinue"))}
        </CustomButton>
      </div>
    </div>
  </div>
  </div>;
};
