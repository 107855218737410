import { z } from "zod";
import { itemSchema } from "../../../../backend/types/item";
import { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";

import "./index.scss";
import { roomSchema } from "../../../../backend/types/room";

import { CustomLink } from "../CustomAButton/CustomLink";
import { CustomButton } from "../CustomAButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { CloseSvg } from "../CloseSvg";
import { MediaRender } from "../MediaRender";
import { LanguageContext } from "../../utils/multiLanguageString";
import { museumSchema } from "../../../../backend/types/museum";
import { mediaSchema } from "../../../../backend/types/media";

export const ItemModal = (
  { room, item, museums, medias, close, unplaceItem }: 
  { 
    room: z.infer<typeof roomSchema.shape.items>,
    item: z.infer<typeof itemSchema> | undefined,
    medias: z.infer<typeof mediaSchema>[],
    museums: z.infer<typeof museumSchema>[],
    close: () => void,
    unplaceItem: ({ item }: { item: z.infer<typeof itemSchema> }) => void
  },
) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<z.infer<typeof itemSchema>>();
  const [currentItemMedias, setCurrentItemMedias] = useState<z.infer<typeof mediaSchema>[]>([]);
  const [itemInRoom, setItemInRoom] = useState<boolean>(false);
  const [selectedMedia, setSelectedMedia] = useState<z.infer<typeof mediaSchema>>();

  const navigate = useNavigate();

  useEffect(() => {
    if (item) {
      setCurrentItem(item);
      setCurrentItemMedias(medias.filter((singleMedia) => (item.medias.includes(singleMedia._id) && !singleMedia.hideOutsideExperience )));

      const currentItemInRoom = room.some((singleSpot) => singleSpot.item === item._id);
      setItemInRoom(currentItemInRoom);

      const visibilityTimeout = setTimeout(() => {
        setVisible(true);
      }, 100);

      return () => {clearTimeout(visibilityTimeout);};
    } else {
      setVisible(false);
      const visibilityTimeout = setTimeout(() => {
        setCurrentItem(undefined);
        setCurrentItemMedias([]);
        setItemInRoom(false);
      }, 500);

      return () => {clearTimeout(visibilityTimeout);};
    }
  }, [item, room]);

  ReactModal.setAppElement("#root");

  const { multiLanguageString, getString } = useContext(LanguageContext);
  
  return <ReactModal 
    isOpen={!!currentItem} 
    overlayClassName={`ItemModalOverlay ${visible ? "visible" : "invisible"} ${selectedMedia ? "watchingMedia" : ""}`} 
    className={`ItemModal ${visible ? "visible" : "invisible"}`}
    onRequestClose={close}
  >
    <div className="inner-wrapper">
      {currentItem && !selectedMedia && <>
        <div className="close-wrapper">
          <button onClick={() => {close();}}><CloseSvg /></button>
        </div>
        <div className="image-wrapper">
          <img alt="" src={currentItem.image} />
        </div>
        <div className="title-wrapper">
          <h2>{multiLanguageString(currentItem.name)}</h2>
        </div>
        {currentItem.museums.length > 1 && <div className="link-wrapper">
          {multiLanguageString(getString("linkToOtherMuseum"))}{" "}
          {currentItem.museums.map((singleMuseumId) => multiLanguageString(museums.find((singleMuseum) => singleMuseum._id === singleMuseumId)?.name)).join(", ")}{"."}
        </div>}
        {currentItemMedias.length > 0 && <div className="medias-wrapper">
          <p>{multiLanguageString(getString("collectionWatchRelatedMedia"))}</p>
          {currentItemMedias.map((singleMedia, index) => (
            singleMedia.hideOutsideExperience ? null : <CustomButton key={index} onClick={() => {setSelectedMedia(singleMedia);}}>
              {multiLanguageString(singleMedia.title)}
            </CustomButton>
          ))}
        </div>}
        <div className="room-actions-wrapper">
          {itemInRoom
            ? <CustomButton 
              onClick={() => {
                unplaceItem({ item: currentItem });
                close();
                navigate("/collection", { state: null });
              }}
            >{multiLanguageString(getString("collectionRemove"))}</CustomButton>
            : <CustomLink to="/collection" state={{ addItem: currentItem._id }}>{multiLanguageString(getString("collectionAdd"))}</CustomLink>
          }
        </div>
      </>}
      {
        currentItem && selectedMedia && <>
          <MediaRender 
            media={selectedMedia} 
            outsideExperience 
            context="collection"
            buttonElement={(buttonOnClick) => {
              return <div className="back-wrapper">
                <CustomButton onClick={buttonOnClick} kind="text" preIcon={<CloseSvg />}>{multiLanguageString(getString("mediaClose"))}</CustomButton>
              </div>;
            }}
            buttonOnClick={() => {setSelectedMedia(undefined);}}
          />
          
        </>
      }
    </div>
  </ReactModal>;
};
