import { DetailedHTMLProps, InputHTMLAttributes, ReactNode, useState } from "react";
import "./index.scss";

export const Input = ({ label, labelColor, textColor, activeColor, error, ...props }: { label?: ReactNode, labelColor?: string, textColor?: string, activeColor?: string, error?: string, } & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {
  const [focused, setFocused] = useState<boolean>(false);

  return <div className="Input">
    <label>
      {(label || error) && <div className="labels">
        <span className="label" style={{ color: labelColor }}>{label}</span>
        {error && <span className="error">{error}</span>}
      </div>}
      <span className="control">
        <input 
          onFocus={() => {setFocused(true);}}
          onBlur={() => {setFocused(false);}}
          style={{ color: textColor, outlineColor: focused ? activeColor : "none", boxShadow: focused ? `0px 0px 6px ${activeColor}` : "none" }} 
          {...props}
        />
      </span>
    </label>
  </div>;
};
