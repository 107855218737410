
import { LanguageContext } from "../../utils/multiLanguageString";
import "./index.scss";

import { ReactComponent as LogoSvg } from "./logo.svg";
import { ReactComponent as AppleSvg } from "./apple.svg";
/* import { ReactComponent as FacebookSvg } from "./facebook.svg"; */
import { ReactComponent as GoogleSvg } from "./google.svg";
import { useContext, useState } from "react";

export const Login = () => {
  const { multiLanguageString, getString } = useContext(LanguageContext);

  const [ usingEmailMethod, setUsingEmailMethod ] = useState<boolean>(false);
  const [ emailAddress, setEmailAddress ] = useState("");
  const [ emailSent, setEmailSent ] = useState(false);
  
  return <div className="Login">
    <div className="top-wrapper">
      <div className="inner-wrapper">
        <h1>{multiLanguageString(getString("loginTitle"))}</h1>
        {!usingEmailMethod && <div className="methods">
          <a href={`${process.env.REACT_APP_BASE_AUTH_URL}/login/google?redirectEndpoint=${encodeURIComponent(window.location.href)}`}>
            <div className="icon"><GoogleSvg /></div>
            <div className="text">{multiLanguageString(getString("loginWith"))} Google</div>
          </a>
          <a href={`${process.env.REACT_APP_BASE_AUTH_URL}/login/apple?redirectEndpoint=${encodeURIComponent(window.location.href)}`}>
            <div className="icon"><AppleSvg /></div>
            <div className="text">{multiLanguageString(getString("loginWith"))} Apple</div>
          </a>
          {/* <a href={`${process.env.REACT_APP_BASE_AUTH_URL}/login/facebook?redirectEndpoint=${encodeURIComponent(window.location.href)}`}>
            <div className="icon"><FacebookSvg /></div>
            <div className="text">{multiLanguageString(getString("loginWith"))} Facebook</div>
          </a> */}
          <div className="divider">
            <span></span>
            <span>{multiLanguageString(getString("loginOr"))}</span>
            <span></span>
          </div>
          <button onClick={() => {setUsingEmailMethod(true);}}>
            <div className="text">{multiLanguageString(getString("loginWith"))} {multiLanguageString(getString("loginYourEmail"))}</div>
          </button>
        </div>}
        {usingEmailMethod && <>
          {!emailSent && <div className="methods">
            <label>
              <input placeholder="Email" value={emailAddress} onChange={(event) => {setEmailAddress(event.target.value);}} />
            </label>
            <button onClick={async () => {
              try {
                await fetch(`${process.env.REACT_APP_BASE_AUTH_URL}/login/emailPasswordless?${new URLSearchParams({
                  redirectEndpoint: encodeURIComponent(window.location.href),
                  emailAddress,
                })}`);
              } catch (error) {}

              setEmailSent(true);
            }}>
              <div className="text">{multiLanguageString(getString("loginSendEmail"))}</div>
            </button>
            <div className="subtext">
              {multiLanguageString(getString("loginSubtext"))}
            </div>
          </div>}
          {emailSent && <div className="methods">
            {multiLanguageString(getString("loginCheckEmail"))}
          </div>}
        </>}
      </div>
    </div>
    <div className="bottom-wrapper">
      <LogoSvg />
    </div>
  </div>;
};
