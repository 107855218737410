import { serialize, deserialize } from "superjson";
import { v4 as uuid } from "uuid"; 

/**
 * This type stores information about the session and the current user
 */
export type Session = {
  _id: string
  user: {
    _id: string,
  },
};

/**
 * An authorize function takes a token and retrieves (1) whether the corresponding user is authorized, (2) info about the session and the user
 */
export type AuthorizeFunction = (({ token }: { token: string }) => (Promise<Session> | undefined));

/**
 * This object type contains endpoints names as keys, and an object containing the authorize function and the procedure as values
 */
export type Endpoints = { [key: string]: { authorize: AuthorizeFunction, procedure: (...args: any) => Promise<any> } };

export const makeRemoteCallHandler = <
  MyEndpoints extends Endpoints,
>(

) => async <
  ProcedureName extends keyof MyEndpoints,
  Procedure extends (MyEndpoints)[ProcedureName]["procedure"],
  ProcedureParameters extends Parameters<Procedure>[0],
  ReturnedValue extends ReturnType<Procedure>,
>(
  name: ProcedureName,
  parameters: ProcedureParameters,
): Promise<ReturnedValue> => {
  const token = localStorage.getItem("sesameToken") || "";

  const body = {
    type: "request",
    procedure: {
      procedureName: name,
      procedureParameters: serialize(parameters),
    },
    meta: {
      token,
      requestId: uuid(),
    },
  };

  const response = await fetch(process.env.REACT_APP_BASE_API_URL as string, {
    body: JSON.stringify(body),
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
  });

  const responseObject = await response.json();

  if (responseObject?.type === "error") {
    throw new Error("REMOTE_ERROR", { cause: responseObject?.error });
  }

  return deserialize(responseObject?.returnedValue);
};

//export type RemoteCallHandler<MyEndpoints extends Endpoints> = ReturnType<typeof makeRemoteCallHandler<MyEndpoints>>;
