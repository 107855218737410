import { LanguageContext } from "../../../utils/multiLanguageString";
import { CustomButton } from "../../../components/CustomAButton/CustomButton";
import { MomentProps } from "..";
import { useContext, useEffect, useState } from "react";

import { z } from "zod";
import { itemSchema } from "../../../../../backend/types/item";
import { ItemGivenMoment } from "../ItemGivenMoment";
import { ArrowSvg } from "../../../components/ArrowSvg";
import { ExitSvg } from "../../../components/ExitSvg";
import { MediaRender } from "../../../components/MediaRender";

export const AdditionalMediaMoment = ({ moment, goForward, visible, characters, items, exit, museums, medias }: MomentProps) => {
  if (moment.type !== "additionalMedia") return <></>;

  const character = characters.find((singleCharacter) => singleCharacter._id === moment.characterId);

  const [mediaIndex, setMediaIndex] = useState<number | undefined>();
  const [seenMediaIndexes, setSeenMediaIndexes] = useState<number[]>([]);
  const [itemToBeGiven, setItemToBeGiven] = useState<z.infer<typeof itemSchema> | undefined>();
  const [showItemToBeGiven, setShowItemToBeGiven] = useState<boolean>(false);
  const [itemToBeGivenNextFunction, setItemToBeGivenNextFunction] = useState<{ action: () => void }>({ action: () => {} });
  const [itemsGivenNow, setItemsGivenNow] = useState<z.infer<typeof itemSchema>["_id"][]>([]);
  const [forcedInvisibility, setForcedInvisibility] = useState<boolean>(false);

  const openMedia = (index: number) => {
    setMediaIndex(index);

    const singleItemToBeGiven = items.find((singleItem) => moment.medias[index].itemGiven?.itemId === singleItem._id);
    
    if (!itemsGivenNow.includes(singleItemToBeGiven?._id || "") || !moment.medias[index].itemGiven?.hideItem) {
      setItemToBeGiven(singleItemToBeGiven);
      setItemsGivenNow((previousItemsGivenNow) => [...previousItemsGivenNow, singleItemToBeGiven?._id || ""]);
    }

    setSeenMediaIndexes((previousSeenMediaIndexes) => [...previousSeenMediaIndexes, index]);
  };

  const closeMedia = () => {
    let nextAction = () => {
      setForcedInvisibility(true);
      

      setTimeout(() => {
        setMediaIndex(undefined);
        setItemToBeGiven(undefined);
        setShowItemToBeGiven(false);
      }, 250);

      setTimeout(() => {
        setForcedInvisibility(false);
      }, 500);
    };

    if (moment.medias.every((_, index) => seenMediaIndexes.includes(index))) {
      nextAction = () => {
        goForward();
      };
    }

    if (itemToBeGiven) {
      setForcedInvisibility(true);

      setTimeout(() => {
        setShowItemToBeGiven(true);
        setItemToBeGivenNextFunction({ action: nextAction });
      }, 250);

      setTimeout(() => {
        setForcedInvisibility(false);
      }, 500);
    } else {
      nextAction();
    }
  };

  useEffect(() => {
    if (moment.medias.length === 1) {
      openMedia(0);
    }
  }, []);
  
  const { multiLanguageString, getString } = useContext(LanguageContext);
  
  return (<>
    {showItemToBeGiven && typeof mediaIndex !== "undefined" &&  moment.medias[mediaIndex].itemGiven && moment.medias[mediaIndex].itemGiven?.itemId
    && <ItemGivenMoment {...{ 
      moment: {
        type: "itemGiven",
        itemId: moment.medias[mediaIndex].itemGiven?.itemId as string,
        characterId: moment.medias[mediaIndex].itemGiven?.characterId,
        dialogueText: moment.medias[mediaIndex].itemGiven?.dialogueText,
      }, 
      goForward: itemToBeGivenNextFunction.action, 
      visible: forcedInvisibility ? false : visible, 
      characters,
      items,
      museums,
      medias,
      exit, 
    }} />}
    {!showItemToBeGiven && <div className={`Moment ${`type-${moment.type}`} ${forcedInvisibility ? "invisible" : visible ? "visible" : "invisible"}`}>
      <div className="total-wrapper">
        
        {typeof mediaIndex === "undefined" && moment.medias && moment.medias.length > 0 && <>
          <div className="exit-wrapper">
            <CustomButton onClick={exit} aria-label={multiLanguageString(getString("momentExit"))} postIcon={<ExitSvg />} />
          </div>
          <div className="main-wrapper">
            {character && moment.dialogueText && <div className="balloon-wrapper">
              {character && <div className="name">{multiLanguageString(character.name)}</div>}
              {moment.dialogueText
              && <div
                className="text"
                dangerouslySetInnerHTML={{ __html: seenMediaIndexes.length > 0 ? multiLanguageString(getString("momentMediaWantMore")) : multiLanguageString(moment.dialogueText) }}
              />
              }
            </div>}
            <div className="media-list-wrapper">
              {moment.medias.map((singleMedia, index) => {
                const actualMedia = medias.find((singleActualMedia) => singleActualMedia._id === singleMedia.media);
                const item = items.find((singleItem) => singleMedia.itemGiven?.itemId === singleItem._id);
                const mediaSeen = seenMediaIndexes.some((singleIndex) => singleIndex === index);
                return (
                  <CustomButton 
                    kind={mediaSeen ? "outline" : "secondary"}
                    className={`${mediaSeen ? "seen" : ""}`} 
                    onClick={() => {openMedia(index);}}
                    key={index}
                    postIcon={
                      !singleMedia.itemGiven?.hideItem && item 
                        ? <img style={{ height: "2em" }} src={item.image} alt="" />
                        : null
                    }
                  >
                    {multiLanguageString(actualMedia?.title || [])}
                  </CustomButton>
                );
              })}
            </div>
            <div className="skip-wrapper">
              <CustomButton kind="text" onClick={goForward} postIcon={<ArrowSvg />}>
                {multiLanguageString(getString("momentMediaSkip"))}
              </CustomButton>
            </div>
          </div>
        </>}
        {typeof mediaIndex !== "undefined" && moment.medias[mediaIndex] && <>
          <MediaRender 
            context="experience" 
            media={medias.find((singleActualMedia) => singleActualMedia._id === moment.medias[mediaIndex].media)} 
            buttonElement={(buttonOnClick) => {
              return <div className="actions-wrapper">
                <CustomButton 
                  onClick={buttonOnClick}
                  kind="outline" 
                  postIcon={
                    !moment.medias[mediaIndex].itemGiven?.hideItem && itemToBeGiven 
                      ? <img style={{ height: "2em", marginLeft: ".5em" }} src={itemToBeGiven.image} alt="" /> 
                      : <ArrowSvg />
                  }
                >
                  {multiLanguageString(getString("momentContinue"))}
                </CustomButton>
              </div>;
            }}
            buttonOnClick={closeMedia}
          />
          
        </>}
      </div>
    </div>}
  </>);
};
