import { ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";

import "./index.scss";

export const CustomLink = ({ kind, className, children, preIcon, postIcon, ...props }: { kind?: "primary" | "secondary" | "outline" | "text", preIcon?: ReactNode, postIcon?: ReactNode } & LinkProps & React.RefAttributes<HTMLAnchorElement>) => {
  if (!kind) kind = "primary";
  
  return <Link {...props} className={`CustomLink ${className || ""} kind-${kind}`}>
    {preIcon && <span className="pre-icon">{preIcon}</span>}
    {children && <span className="text">{children}</span>}
    {postIcon && <span className="post-icon">{postIcon}</span>}
  </Link>;
};
