import { LanguageContext } from "../../../utils/multiLanguageString";
import { CustomButton } from "../../../components/CustomAButton/CustomButton";

import { MomentProps } from "..";
import { ExitSvg } from "../../../components/ExitSvg";
import { useContext, useEffect, useState } from "react";
import { RightSvg } from "../../../components/RightSvg";
import { WrongSvg } from "../../../components/WrongSvg";
import { shuffleArray } from "../../../utils/shuffleArray";
import { getArrayRange } from "../../../utils/getArrayRange";
import ga4 from "react-ga4";

const ErrorIcon = ({ top, left }: { top: number, left: number }) => {
  return <span className="ErrorIcon" style={{ top, left }}>
    <WrongSvg />
  </span>;
};

const RightIcon = ({ top, left }: { top: number, left: number }) => {
  return <span className="RightIcon" style={{ top, left }}>
    <RightSvg />
  </span>;
};

const AnswerBox = (
  { answer, rightIndex, nextStep, tryStep }: 
  {
    answer: Extract<MomentProps["moment"], { type: "orderingGame" }>["steps"][number],
    rightIndex: number,
    nextStep: number,
    tryStep: (index: number) => boolean,
  }) => {
  const [errorSpots, setErrorSpots] = useState<{ top: number, left: number }[]>([]);
  const [rightSpots, setRightSpots] = useState<{ top: number, left: number }[]>([]);

  const addErrorSpot = ({ top, left }: { top: number, left: number }) => {
    setErrorSpots((previousErrorSpots) => [...previousErrorSpots, { top, left }]);
  };

  const addRightSpot = ({ top, left }: { top: number, left: number }) => {
    setRightSpots((previousRightSpots) => [...previousRightSpots, { top, left }]);
  };

  const { multiLanguageString } = useContext(LanguageContext);
  
  return <div 
    className={`AnswerBox ${rightIndex < nextStep ? "done" : ""}`}
    onClick={(event) => {
      const result = tryStep(rightIndex);
    
      if (!result) {
        addErrorSpot({ top: event.nativeEvent.offsetY, left: event.nativeEvent.offsetX });
      } else {
        addRightSpot({ top: event.nativeEvent.offsetY, left: event.nativeEvent.offsetX });
      }
    }} 
  >
    {answer.stepType === "text" &&
     <div className="text">
       {multiLanguageString(answer.text)}
     </div>
    }
    {errorSpots.map(({ left, top }, index) => <ErrorIcon key={`wrong${index}`} {...{ left, top }} />)}
    {rightSpots.map(({ left, top }, index) => <RightIcon key={`right${index}`} {...{ left, top }} />)}
  </div>;
};

const StepFeedback = (
  { index, step, cPlaceholder }:
  { index: number, step: Extract<MomentProps["moment"], { type: "orderingGame" }>["steps"][number] | undefined, cPlaceholder?: Extract<MomentProps["moment"], { type: "orderingGame" }>["steps"][number]["placeholderLabel"] },
) => {
  const { multiLanguageString } = useContext(LanguageContext);
  
  return <div className={`StepFeedback ${step ? "done" : ""}`}>
    <div className="step-number">{cPlaceholder ? multiLanguageString(cPlaceholder) : index + 1}</div>
    <div className="step-wrap">{step?.stepType === "text" && <span className="text">{multiLanguageString(step.text)}</span>}</div>
  </div>;
};

export const OrderingGameMoment = ({ moment, goForward, visible, characters, exit }: MomentProps) => {
  if (moment.type !== "orderingGame") return <></>;

  const [randomAnswers] = useState<((typeof moment.steps)[number] & { rightIndex: number })[]>(shuffleArray([...moment.steps].map((step, index) => ({ ...step, rightIndex: index }))));

  const character = characters.find((singleCharacter) => singleCharacter._id === moment.characterId);

  const [gameCleared, setGameCleared] = useState<boolean>(false);
  const [nextStep, setNextStep] = useState<number>(0);

  const tryStep = (index: number) => {
    if (index === nextStep) {
      setNextStep(index + 1);
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (nextStep === moment.steps.length) setGameCleared(true);
  }, [nextStep]);

  useEffect(() => {
    if (gameCleared) {
      ga4.event("game_step_cleared", {
        game: moment.analyticsName,
      }); 

      const clearGameTimeout = setTimeout(() => {
        goForward();
      }, moment.congratulate ? 1500 : 500);

      return () => {clearTimeout(clearGameTimeout);};
    }
  }, [gameCleared]);

  const { multiLanguageString, getString } = useContext(LanguageContext);
  
  return <div className={`Moment game ${`type-${moment.type}`} ${visible ? "visible" : "invisible"}`}><div className="total-wrapper">
    <div className="exit-wrapper">
      <CustomButton onClick={exit} aria-label={multiLanguageString(getString("momentExit"))} postIcon={<ExitSvg />} />
    </div>
    <div className="main-wrapper">
      {character && moment.dialogueText && <div className="balloon-wrapper">
        {character && <div className="name">{multiLanguageString(character.name)}</div>}
        {moment.dialogueText && <div className="text" dangerouslySetInnerHTML={{ __html: multiLanguageString(moment.dialogueText) }} />}
      </div>}
      <div className={`bottom-wrapper ${(gameCleared && moment.congratulate) ? "game-cleared" : ""}`}>
        <div className="main-wrapper">
          <div className="right-steps-wrapper">
            {
              getArrayRange(0, moment.steps.length - 1).map((step, index) => <StepFeedback cPlaceholder={moment.steps[step].placeholderLabel} key={index} step={step < nextStep ? moment.steps[step] : undefined} {...{ index }} />)
            }
          </div>
          <div className="answer-list-wrapper">
            {randomAnswers.map((answer, index) => <AnswerBox key={index} rightIndex={answer.rightIndex} {...{ answer, nextStep, tryStep }} />)}
          </div>
        </div>
        {(gameCleared && moment.congratulate) && <div className="game-congratulations">{multiLanguageString(getString("momentGameCongratulations"))}</div>}
      </div>
    </div>
  </div>
  </div>;
};
