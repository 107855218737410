import { QrReader } from "react-qr-reader";
import "./index.scss";
import { useContext, useState } from "react";
import { LanguageContext } from "../../utils/multiLanguageString";

import { useNavigate } from "react-router-dom";

export const QrScan = () => {
  const { multiLanguageString, getString } = useContext(LanguageContext);

  const [matchFound, setMatchFound] = useState(false);

  const navigate = useNavigate();

  const parseUrl = (text: string) => {
    const regex = /\/station\/[0-9a-zA-Z]+/gm;
    const match = regex.exec(text);
    if (match && match[0]) {
      const stationId = match[0].replace("/station/", "");
      setMatchFound(true);
      navigate(`/station/${stationId}`, { replace: true });
    }
  };
  
  return <div className="QrScan">
    {!matchFound && <QrReader
      constraints={{
        facingMode: "environment",
      }}
      className="camera"
      onResult={(result) => {
        if (!!result) {
          if (result.getText()) parseUrl(result.getText());
        }
      }}
      /* style={{ width: '100%' }} */
    />}
    <div className="infoBox">
      {multiLanguageString(getString("scanQrInstructions"))}
    </div>
  </div>;
};
