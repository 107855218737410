import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from "react";

import "./index.scss";

export const CustomButton = ({ kind, className, children, preIcon, postIcon, ...props }: { kind?: "primary" | "secondary" | "outline" | "text", preIcon?: ReactNode, postIcon?: ReactNode } & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
  if (!kind) kind = "primary";
  
  return <button {...props} className={`CustomButton ${!children ? "text-less" : ""} ${className || ""} kind-${kind}`}>
    {preIcon && <span className="pre-icon">{preIcon}</span>}
    {children && <span className="text">{children}</span>}
    {postIcon && <span className="post-icon">{postIcon}</span>}
  </button>;
};
