export const mapStyles = [
  {
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "administrative.country",
    "stylers": [
      {
        "color": "#35631f",
      },
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "administrative.locality",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "administrative.neighborhood",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "administrative.province",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "landscape.man_made",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "landscape.natural",
    "stylers": [
      {
        "color": "#d4e8bb",
      },
      {
        "visibility": "on",
      },
    ],
  },
  {
    "featureType": "landscape.natural",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "landscape.natural.landcover",
    "stylers": [
      {
        "color": "#e8f5d7",
      },
      {
        "visibility": "on",
      },
    ],
  },
  {
    "featureType": "landscape.natural.landcover",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "landscape.natural.terrain",
    "stylers": [
      {
        "color": "#85b62a",
      },
      {
        "visibility": "simplified",
      },
    ],
  },
  {
    "featureType": "landscape.natural.terrain",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "road.arterial",
    "stylers": [
      {
        "color": "#fafcfc",
      },
      {
        "visibility": "on",
      },
      {
        "weight": 1,
      },
    ],
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "road.highway",
    "stylers": [
      {
        "color": "#ffe68a",
      },
      {
        "visibility": "on",
      },
      {
        "weight": 1,
      },
    ],
  },
  {
    "featureType": "road.highway",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "road.local",
    "stylers": [
      {
        "color": "#faf3dd",
      },
      {
        "visibility": "on",
      },
      {
        "weight": 0.5,
      },
    ],
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#dcf1f2",
      },
      {
        "visibility": "on",
      },
    ],
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#23b5c9",
      },
      {
        "visibility": "on",
      },
    ],
  },
];
