import { useEffect, useState, createContext, ReactNode } from "react";
import { multiLanguageStringSchema } from "../../../backend/types/multiLanguageString";
import { z } from "zod";
import { makeRemoteCallHandler } from "../libProcedureWire";
import { Endpoints } from "../../../backend";

const remoteCall = makeRemoteCallHandler<Endpoints>();

export const LanguageContext = createContext<
{ 
  multiLanguageString: (multiLanguageArray: { string: string; lang: "it" | "en"; }[] | undefined) => string,
  getString: (name: string) => { string: string; lang: "it" | "en"; }[],
  currentLanguage: string,
  setCurrentLanguage: React.Dispatch<React.SetStateAction<string>>,
  //@ts-expect-error
}>(undefined);
const LanguageProvider = LanguageContext.Provider;

export const LanguageWrapper = ({ children }: { children: ReactNode }) => {
  const browserLanguage = (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      // @ts-expect-error
      navigator.userLanguage as string;

  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("lang") || browserLanguage.slice(0, 2) || "it",
  );

  const [strings, setStrings] = useState<{ [key: string]: z.infer<typeof multiLanguageStringSchema> }>({});

  useEffect(() => {
    (async () => {
      const stringListCall = await remoteCall("stringList", {});
      setStrings(stringListCall.strings);
    })();
  }, []);

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) html.setAttribute("lang", currentLanguage);
    localStorage.setItem("lang", currentLanguage);
  }, [currentLanguage]);

  const multiLanguageString = (multiLanguageArray: z.infer<typeof multiLanguageStringSchema> | undefined) => {
    if (typeof multiLanguageArray === "undefined") return "";
    
    return multiLanguageArray.find((item) => item.lang === currentLanguage)?.string || multiLanguageArray.find((item) => item.lang === "it")?.string || "";
  };

  const getString = (name: string) => [...(strings[name] || [])];

  return (
    <LanguageProvider
      value={{ multiLanguageString, getString, currentLanguage, setCurrentLanguage }}
    >
      {children}
    </LanguageProvider>
  );
};
