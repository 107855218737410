import { z } from "zod";
import { museumSchema } from "../../../../backend/types/museum";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";

import "./index.scss";
import { mapStyles } from "./mapStyles";
import { Link, useNavigate } from "react-router-dom";
import { LanguageContext } from "../../utils/multiLanguageString";
import { BottomNavigation } from "../../components/BottomNavigation";
import { useContext, useEffect, useState } from "react";
import { pinpointSchema } from "../../../../backend/types/pinpoint";

import smallPin from "./smallPin.svg";
import bigPin from "./bigPin.svg";
import herePin from "./here.svg";
import fogPin from "./fog.png";
import { ReactComponent as GeolocationIco } from "./geolocation.svg";

import { getDistance } from "geolib";
import { CustomButton } from "../../components/CustomAButton/CustomButton";
import ReactModal from "react-modal";
import { CloseSvg } from "../../components/CloseSvg";
import { makeRemoteCallHandler } from "../../libProcedureWire";
import { Endpoints } from "../../../../backend";
import { skinParameters } from "../../utils/skins/skinAssets";
import { OnboardingName } from "../../onboardingStations";
import { mediaSchema } from "../../../../backend/types/media";
import { MediaRender } from "../../components/MediaRender";
import ga4 from "react-ga4";

const remoteCall = makeRemoteCallHandler<Endpoints>();
export type RemoteCallHandler = typeof remoteCall;

export const Home = (
  { museums, pinpoints, fog, medias, clearOnboarding }: 
  { museums: z.infer<typeof museumSchema>[] | undefined, 
    pinpoints: z.infer<typeof pinpointSchema>[] | undefined, 
    medias: z.infer<typeof mediaSchema>[] | undefined, 
    fog?: boolean,
    clearOnboarding?: (onboarding: OnboardingName) => void,
  },
) => {
  const navigate = useNavigate();

  const { multiLanguageString, getString } = useContext(LanguageContext);
  const [map, setMap] = useState<google.maps.Map>();
  const [far, setFar] = useState<boolean>(true);
  const [center] = useState({
    lat: 45.9718226,
    lng: 9.2409563,
  });

  const [currentPosition, setCurrentPosition] = useState<{ lat: number, lng: number } | undefined>();
  const [geolocationGranted, setGeolocationGranted] = useState<boolean>(false);
  const [gettingLocation, setGettingLocation] = useState<boolean>(false);

  const [currentPinpoint, setCurrentPinpoint] = useState<z.infer<typeof pinpointSchema> | undefined>();
  const [openPinpoint, setOpenPinpoint] = useState<z.infer<typeof pinpointSchema> | undefined>();
  const [selectedMedia, setSelectedMedia] = useState<z.infer<typeof mediaSchema>>();

  const [currentPinpointDistance, setCurrentPinpointDistance] = useState<number | undefined>();

  const [skinGiven, setSkinGiven] = useState<string | undefined>();
  const [numberVisited, setNumberVisited] = useState<number | undefined>();

  const [cleared, setCleared] = useState<boolean>(false);

  const [finishedMuseums, setFinishedMuseums] = useState<string[]>([]);

  const [geoError, setGeoError] = useState<boolean>(false);

  useEffect(() => {
    ga4.send({ hitType: "pageview", page: "/", title: "homescreen" });
  }, []);

  useEffect(() => {
    if (openPinpoint) {
      ga4.send({ hitType: "pageview", page: "/#pinpointCard", title: "pinpointCard", pinpoint: openPinpoint.analyticsName });
    }
  }, [openPinpoint]);

  useEffect(() => {
    (async () => {
      (async () => {
        try {
          const finishedMuseumsCall = await remoteCall("finishedMuseumRead", {});
          setFinishedMuseums(finishedMuseumsCall.finishedMuseums);
        } catch (error) {}
      })();

      const geolocationPermission = await navigator.permissions.query({ name: "geolocation" });
      
      if (geolocationPermission.state === "granted") {
        setGettingLocation(true);

        navigator.geolocation.getCurrentPosition(
          (position) => {
            setGeolocationGranted(true);
            setGettingLocation(false);
            setCurrentPosition({ lat: position.coords.latitude, lng: position.coords.longitude });
          },
          () => {
            setGeolocationGranted(false);
            setGettingLocation(false);
            setCurrentPosition(undefined);
          },
        );
      }
    })();
  }, []);

  const getLocation = () => {
    setGettingLocation(true);
    
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setGeolocationGranted(true);
        setGettingLocation(false);
        setCurrentPosition({ lat: position.coords.latitude, lng: position.coords.longitude });
        ga4.event("geolocation_obtained");
      },
      () => {
        setGeolocationGranted(false);
        setGettingLocation(false);
        setCurrentPosition(undefined);
        setGeoError(true);
      },
    );
  };

  useEffect(() => {
    if (currentPosition) {
      if (
        currentPosition.lat < 46.241 &&
        currentPosition.lat > 45.774 &&
        currentPosition.lng < 9.486 &&
        currentPosition.lng > 9.040
      ) {
        const timeout = setTimeout(() => {
          map?.setCenter(currentPosition);
          map?.setZoom(13);
        }, 500);
    
        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [currentPosition]);

  useEffect(() => {
    if (currentPosition && currentPinpoint) {
      const newDistance = getDistance(currentPosition, currentPinpoint.position);
      setCurrentPinpointDistance(newDistance);
    } else {
      setCurrentPinpointDistance(undefined);
    }
  }, [currentPosition, currentPinpoint]);

  ReactModal.setAppElement("#root");

  const startOpenPinpoint = async (pinpoint: z.infer<typeof pinpointSchema>) => {
    try {
      const { visitedSoFar, isNewPinpoint } = await remoteCall("visitedPinpointSave", { pinpointId: pinpoint._id });

      if (isNewPinpoint && [1, 2, 5, 10].includes(visitedSoFar)) {
        (async () => {
          try {
            const currentCollectionSkins = (await remoteCall("collectionRead", {})).skins;
            const newCollectionSkins = currentCollectionSkins.includes(`additional${visitedSoFar}`) ? currentCollectionSkins : [...currentCollectionSkins, `additional${visitedSoFar}`];
            await remoteCall("collectionSave", { skins: newCollectionSkins });
          } catch (e) {
            
          }
        })();
        setSkinGiven(`additional${visitedSoFar}`);
        ga4.event("skin_given", {
          skin_reason: "pinpoints_visited",
          skin_name: `additional${visitedSoFar}`,
        });
        setNumberVisited(visitedSoFar);
      }

      setOpenPinpoint(pinpoint);

    } catch (error) {}
  };

  return (
    <div className={`Home ${fog ? "foggy-map" : ""}  ${cleared ? "cleared" : ""}`}>
      
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "100%",
        }}
        center={center}
        zoom={10.2}
        onLoad={(currentMap) => setMap(currentMap)}
        onZoomChanged={() => {if ((map?.getZoom() || 10.2) < 10.5 || (map?.getZoom() || 10.2) > 11.5) setFar((map?.getZoom() || 10.2) < 12);}}
        onClick={() => {setCurrentPinpoint(undefined);}}
        options={{
          gestureHandling: fog ? "none" : "greedy",
          zoomControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          keyboardShortcuts: false,
          styles: mapStyles,
          restriction: {
            latLngBounds: {
              north: 46.831157,
              south: 45.175114,
              east: 10.363009,
              west: 8.112288,
            },
            strictBounds: true,
          },
          isFractionalZoomEnabled: true,
        }}
      >
        {(museums && pinpoints) && <>
          {currentPosition && <Marker zIndex={1} icon={herePin} title={multiLanguageString(getString("getPosition"))} position={currentPosition} />}
          {pinpoints.map(({ position, _id, name, ...singlePinpoint }) => <Marker 
            opacity={fog ? cleared ? 1 : 0 : 1}
            zIndex={2}
            title={multiLanguageString(name)} 
            onClick={() => {
              if (far || fog) return;
            
              if (_id === currentPinpoint?._id) {
                setCurrentPinpoint(undefined);
                return;
              }
          
              setCurrentPinpoint({ position, _id, name, ...singlePinpoint });
            }}
            icon={far ? smallPin : bigPin} 
            key={_id} 
            {...{ position }}
          />)}
          {fog && museums.map(({ position, _id, name }) => <Marker 
            opacity={cleared ? 0 : 1}
            zIndex={4} 
            title={multiLanguageString(name)} 
            icon={{
              url: fogPin,
              anchor: new google.maps.Point(290, 211),
            }}
            key={_id} 
            onClick={() => {
              setCleared(true);

              setTimeout(() => {
                if (clearOnboarding) clearOnboarding("home2");
              }, 2500);
            }} 
            {...{ position }} 
          />)}
          {museums.map(({ position, _id, mapPin, visitedMapPin, name }) => <Marker 
            opacity={fog ? (cleared ? 1 : 0) : 1}
            zIndex={3} 
            title={multiLanguageString(name)}
            icon={finishedMuseums.includes(_id) ? visitedMapPin : mapPin} 
            key={_id} 
            onClick={() => {
              if (!fog) {
                navigate(`/museum/${_id}`, { state: {} });
              }
            }} 
            {...{ position }} 
          />)}
          {currentPinpoint && <InfoWindow
            position={currentPinpoint?.position}
            options={
              { pixelOffset: new google.maps.Size(0, -30) }
            }
            onCloseClick={() => {setCurrentPinpoint(undefined);}}
          >
            <div className="pinpoint-info-window">
              <div className="wrapper">
                {currentPinpoint.photo && <img alt="" src={currentPinpoint.photo} />}
                <div className="text-wrapper">
                  <h3>{multiLanguageString(currentPinpoint.name)}</h3>
                  {currentPinpoint.museums && currentPinpoint.museums.length > 0 && <div className="museums-wrapper">
                    {multiLanguageString(getString("pinpointMuseumLink"))}
                    {" "}
                    {museums
                      .filter((singleMuseum) => currentPinpoint.museums?.includes(singleMuseum._id))
                      .map((singleMuseum) => <span key={singleMuseum._id} className="museum-link">
                        <Link to={`/museum/${singleMuseum._id}`}>
                          {multiLanguageString(singleMuseum.name)}
                        </Link>
                      </span>)}
                  </div>}
                  <div className="more-wrapper">
                    {typeof currentPinpointDistance !== "undefined" && currentPinpointDistance > (parseInt(process.env.REACT_APP_PINPOINT_DISTANCE as string))
                    && <div className="too-far">
                      <p>{multiLanguageString(getString("pinpointTooFar"))}</p>
                    </div>}
                    {typeof currentPinpointDistance !== "undefined" && currentPinpointDistance <= (parseInt(process.env.REACT_APP_PINPOINT_DISTANCE as string))
                    && <div className="here">
                      <p>{multiLanguageString(getString("pinpointHere"))}</p>
                      <CustomButton onClick={() => {startOpenPinpoint(currentPinpoint);}}>{multiLanguageString(getString("pinpointOpen"))}</CustomButton>
                    </div>}
                    {typeof currentPinpointDistance === "undefined" 
                    && <div className="no-geolocation">
                      <p>{multiLanguageString(getString("pinpointNoGeolocation"))}</p>
                      <CustomButton onClick={getLocation}>{multiLanguageString(getString("pinpointActivateGeolocation"))}</CustomButton>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </InfoWindow>}

        </>}
      </GoogleMap>

      {openPinpoint && !selectedMedia && museums && medias && <ReactModal 
        isOpen={!!openPinpoint} 
        overlayClassName="pinpointOverlay" 
        className="PinpointModal"
        onRequestClose={() => {setOpenPinpoint(undefined);setSkinGiven(undefined);setNumberVisited(undefined);}}
      >
        <div className="close-wrapper">
          <button onClick={() => {setOpenPinpoint(undefined);setSkinGiven(undefined);setNumberVisited(undefined);}}><CloseSvg /></button>
        </div>
        {skinGiven && numberVisited && <div className="skin-congratulation">
          <div className="left">
            {/* @ts-expect-error */}
            <img src={skinParameters[skinGiven].image} alt="" />
          </div>
          <div className="right">
            <p className="congratulation">
              {multiLanguageString(getString("pinpointCongratsTitle"))}{" "}
            </p>
            <p>
              {multiLanguageString(getString("pinpointCongratsYouVisited"))}{" "}
              {numberVisited === 1 ? multiLanguageString(getString("pinpointCongratsOneWord")) : numberVisited}{" "}
              {numberVisited === 1 ? multiLanguageString(getString("pinpointCongratsPoint")) : multiLanguageString(getString("pinpointCongratsPoints"))}{" "}
              {multiLanguageString(getString("pinpointCongratsThereforeWinSkin"))}
            </p>
          </div>
        </div>}
        {openPinpoint.photo && <img className="pinpointImage" alt="" src={openPinpoint.photo} />}
        <div className="text-wrapper">
          <h3>{multiLanguageString(openPinpoint.name)}</h3>
          {openPinpoint.museums && openPinpoint.museums.length > 0 && <div className="museums-wrapper">
            {multiLanguageString(getString("pinpointMuseumLink"))}
            {" "}
            {museums
              .filter((singleMuseum) => openPinpoint.museums?.includes(singleMuseum._id))
              .map((singleMuseum) => <span key={singleMuseum._id} className="museum-link">
                <Link to={`/museum/${singleMuseum._id}`}>
                  {multiLanguageString(singleMuseum.name)}
                </Link>
              </span>)}
          </div>}
          {openPinpoint && <div className="detail-text-wrapper">
            <div dangerouslySetInnerHTML={{ __html: multiLanguageString(openPinpoint.text) }} />
          </div>}
        </div>
        {openPinpoint.medias && openPinpoint.medias.length > 0 && <div className="medias-wrapper">
          <p>{multiLanguageString(getString("homeMediaIntro"))}</p>
          {openPinpoint.medias.map((mediaId) => {
            const media = medias.find((singleMedia) => singleMedia._id === mediaId);
            return media ? <CustomButton key={mediaId} onClick={() => {setSelectedMedia(media);}} kind="outline">{multiLanguageString(media.title)}</CustomButton> : null;
          })}
          <p>{multiLanguageString(getString("homeMediaMore"))}</p>
        </div>}
      </ReactModal>}

      {
        openPinpoint && selectedMedia && <ReactModal 
          isOpen={!!selectedMedia} 
          overlayClassName="mediaOverlay" 
          className="MediaModal"
          onRequestClose={() => {setSelectedMedia(undefined);}}
        >
          <div className="inner-wrapper">
            <MediaRender 
              context="pinpoints" 
              media={selectedMedia} 
              outsideExperience 
              buttonElement={(buttonOnClick) => {
                return <div className="back-wrapper">
                  <CustomButton onClick={buttonOnClick} kind="text" preIcon={<CloseSvg />}>{multiLanguageString(getString("mediaClose"))}</CustomButton>
                </div>;
              }}
              buttonOnClick={() => {setSelectedMedia(undefined);}}
            />
            
          </div>
        </ReactModal>
      }

      {!geolocationGranted && !fog && <button className="geolocationButton" onClick={getLocation}>
        {gettingLocation 
          ? <span className="loading"></span>
          : <GeolocationIco />}
      </button>}

      {!fog && <BottomNavigation activeItem="map" />}

      {
        geoError && <ReactModal 
          isOpen={!!geoError} 
          overlayClassName="geoErrorOverlay" 
          className="GeoError"
          onRequestClose={() => {setGeoError(false);}}
        >
          <div className="inner-wrapper">
            <div className="mod-text">
              <b>{multiLanguageString(getString("geoErrorTitle"))}</b><br/>
              {multiLanguageString(getString("geoErrorText"))}
            </div>
            <div className="back-wrapper">
              <CustomButton onClick={() => {setGeoError(false);}} kind="text" preIcon={<CloseSvg />}>{multiLanguageString(getString("geoErrorClose"))}</CustomButton>
            </div>
          </div>
        </ReactModal>
      }
    </div> 
  );
};
