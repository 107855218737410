import { LanguageContext } from "../../../utils/multiLanguageString";
import { CustomButton } from "../../../components/CustomAButton/CustomButton";

import { MomentProps } from "..";
import { ArrowSvg } from "../../../components/ArrowSvg";
import { ExitSvg } from "../../../components/ExitSvg";
import { CaretLeftSvg } from "../../../components/CaretLeftSvg";
import { useContext } from "react";

export const DialogueMoment = ({ moment, goForward, goBack, allowBack, visible, characters, exit }: MomentProps) => {
  if (moment.type !== "dialogue") return <></>;

  const character = characters.find((singleCharacter) => singleCharacter._id === moment.characterId);
  
  const characterImage = character?.moods.find((singleMood) => singleMood.shortName === moment.moodShortName)?.image;
  
  const { multiLanguageString, getString } = useContext(LanguageContext);
  
  return <div className={`Moment ${`type-${moment.type}`} ${visible ? "visible" : "invisible"}`}><div className="total-wrapper">
    
    <div className="exit-wrapper">
      <CustomButton onClick={exit} aria-label={multiLanguageString(getString("momentExit"))} postIcon={<ExitSvg />} />
    </div>
    <div className="main-wrapper">
      <div className="image-wrapper">
        {characterImage && <img src={characterImage}  alt="" />}
      </div>
      <div className="bottom-wrapper">
        <div className="balloon-wrapper">
          {character && <div className="name">{multiLanguageString(character.name)}</div>}
          <div className="text" dangerouslySetInnerHTML={{ __html: `<p>${multiLanguageString(moment.dialogueText)}</p>` }} />
        </div>
        <div className="actions-wrapper">
          {allowBack && <CustomButton kind="outline" onClick={goBack} postIcon={<CaretLeftSvg />} />}
          <CustomButton onClick={goForward} postIcon={<ArrowSvg />}>
            {multiLanguageString(getString("momentContinue"))}
          </CustomButton>
        </div>
      </div>
    </div>
  </div>
  </div>;
};
