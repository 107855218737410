import { LanguageContext } from "../../../utils/multiLanguageString";
import { CustomButton } from "../../../components/CustomAButton/CustomButton";

import { MomentProps } from "..";
import { ExitSvg } from "../../../components/ExitSvg";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { getArrayRange } from "../../../utils/getArrayRange";
import { RightSvg } from "../../../components/RightSvg";
import { WrongSvg } from "../../../components/WrongSvg";
import { characterSchema } from "../../../../../backend/types/character";
import { z } from "zod";
import ga4 from "react-ga4";

const ClickableHotspot = (
  { 
    top, left, width, height, onClick, cleared, custom,
  }: { 
    top: number, left: number, width: number, height: number, cleared: boolean, custom: string | undefined,
    onClick: (event: SyntheticEvent) => void,
  }) => {
  const [checkPosition, setCheckPosition] = useState<{ top: number, left: number }>({ top: 0, left:0 });

  return <div 
    className="ClickableHotspot"
    style={{
      top: `${top}%`,
      left: `${left}%`,
      width: `${width}%`,
      height: `${height}%`,
    }}
    onClick={(event) => {
      event.stopPropagation();
      if (!cleared) {
        setCheckPosition({ left: event.nativeEvent.offsetX, top: event.nativeEvent.offsetY });
        onClick(event);
      }
    }}
  >
    {cleared 
      && <span className="check" style={{ top: `${checkPosition.top}px`, left: `${checkPosition.left}px` }}>
        {custom ? <img src={custom} alt="" /> : <RightSvg />}
      </span>}
  </div>;
};

const ErrorIcon = ({ top, left, custom }: { top: number, left: number, custom: string | undefined }) => {
  return <span className="ErrorIcon" style={{ top, left }}>
    {custom ? <img src={custom} alt="" /> : <WrongSvg />}
  </span>;
};

/*
(moment, goForward, visible, exit)
  if (moment.type !== "imageTapGame") return <></>;

  const [gameCleared, setGameCleared] = useState<boolean>(false);

useEffect(() => {
    if (gameCleared) {
      const clearGameTimeout = setTimeout(() => {
        goForward();
      }, moment.congratulate ? 1500 : 500);

      return () => {clearTimeout(clearGameTimeout);};
    }
  }, [gameCleared]);

  return <div className={`Moment game ${`type-${moment.type}`} ${visible ? "visible" : "invisible"}`}><div className="total-wrapper">
    <div className="exit-wrapper">
      <CustomButton onClick={exit} aria-label={multiLanguageString(getString("momentExit"))} postIcon={<ExitSvg />} />
    </div>
*/

const ImageTapGameStep = (
  { step, characters, goNextStep, gameCleared, congratulate, stepIndex, gameAnalyticsName }:
  {
    step: Extract<MomentProps["moment"], { type: "imageTapGame" }>["steps"][number],
    characters: z.infer<typeof characterSchema>[],
    goNextStep: () => void,
    gameCleared: boolean,
    congratulate: boolean,
    stepIndex: number,
    gameAnalyticsName?: string,
  },
) => {
  if (!step) return <></>;
  const [clickedHotspots, setClickedHotspots] = useState<number[]>([]);
  const [stepCleared, setStepCleared] = useState<boolean>(false);
  const [errorSpots, setErrorSpots] = useState<{ top: number, left: number }[]>([]);
  const [character, setCharacter] = useState<MomentProps["characters"][number] | undefined>();

  const clearHotspot = (index: number) => {
    setClickedHotspots((previousClickedHotspots) => [...previousClickedHotspots, index]);
  };

  const addErrorSpot = ({ top, left }: { top: number, left: number }) => {
    setErrorSpots((previuosErrorSpots) => [...previuosErrorSpots, { top, left }]);
  };

  useEffect(() => {
    if (stepCleared) {
      ga4.event("game_step_cleared", {
        wrong_tries: errorSpots.length,
        game: gameAnalyticsName,
        step_number: `step-${stepIndex}`,
      }); 
    }
  }, [stepCleared]);

  useEffect(() => {
    if (step.clickableMap.length > 0) {
      const allIndexes = getArrayRange(0, step.clickableMap.length - 1);
    
      if ((step.justOne && clickedHotspots.length > 0) || allIndexes.every((index) => clickedHotspots.includes(index))) {
        setTimeout(() => {
          setStepCleared(true);
        }, 200);
      }
    } else {
      if (step.image) {
        const timeout = setTimeout(() => {
          setStepCleared(true);
        }, 3000);
  
        return () => {clearTimeout(timeout);};
      } else if (step.video) {
        const timeout = setTimeout(() => {
          const video = document.querySelector(".interactive-wrapper video");
          if (video) {
            (video).addEventListener("ended", () => {
              setStepCleared(true);
            });
          }
        }, 500);

        return () => {clearTimeout(timeout);};
      }
    }
  }, [clickedHotspots]);

  useEffect(() => {
    if (stepCleared) {
      
      goNextStep();
    }
  }, [stepCleared]);

  useEffect(() => {
    setCharacter(characters.find((singleCharacter) => singleCharacter._id === step.characterId));
    setClickedHotspots([]);
    setStepCleared(false);
    setErrorSpots([]);
  }, [step]);

  const { multiLanguageString, getString } = useContext(LanguageContext);
  
  return <>
    <div className="main-wrapper">
      {character && step.dialogueText && <div className="balloon-wrapper">
        {character && <div className="name">{multiLanguageString(character.name)}</div>}
        {step.dialogueText && <div className="text" dangerouslySetInnerHTML={{ __html: multiLanguageString(step.dialogueText) }} />}
      </div>}
      <div className={`bottom-wrapper ${(gameCleared && congratulate) ? "game-cleared" : ""}`}>
        <div className="image-tap-wrapper">
          <div 
            className="interactive-wrapper"
            onClick={(event) => {
              if (step.clickableMap.length > 0 && !stepCleared) {
                addErrorSpot({ top: event.nativeEvent.offsetY, left: event.nativeEvent.offsetX });
              }
            }}
          >
            {step.image 
              ? <img src={multiLanguageString(step.image)} alt="" /> 
              : step.video
                ? <video autoPlay muted playsInline onClick={(e) => {e.preventDefault();}}>
                  <source src={`${process.env.REACT_APP_BASE_MEDIA_URL}${multiLanguageString(step.video)}`} type="video/mp4"></source>
                </video>
                : null }
            {step.clickableMap.map(({ top, left, width, height }, index) => (
              <ClickableHotspot 
                key={index}
                cleared={clickedHotspots.includes(index)}
                onClick={() => {clearHotspot(index);}}
                custom={step.customRightIcon ? multiLanguageString(step.customRightIcon) : undefined}
                {...{ top, left, width, height }} 
              />
            ))}
            {
              errorSpots.map(({ left, top }, index) => <ErrorIcon key={`wrong${index}`} {...{ left, top }} custom={step.customWrongIcon ? multiLanguageString(step.customWrongIcon) : undefined} />)
            }
          </div>
        </div>
        {(gameCleared && congratulate) && <div className="game-congratulations">{multiLanguageString(getString("momentGameCongratulations"))}</div>}
      </div>
    </div>
  </>;
};

export const ImageTapGameMoment = (
  { moment, goForward, visible, characters, exit }: MomentProps,
) => {
  if (moment.type !== "imageTapGame") return <></>;

  const [gameCleared, setGameCleared] = useState<boolean>(false);
  const [stepIndex, setStepIndex] = useState<number>(0);

  useEffect(() => {
    if (gameCleared) {
      const clearGameTimeout = setTimeout(() => {
        goForward();
      }, moment.congratulate ? 1000 : 500);

      return () => {clearTimeout(clearGameTimeout);};
    }
  }, [gameCleared]);

  const goNextStep = () => {
    setTimeout(() => {
      setStepIndex((previousStepIndex) => {
        if (previousStepIndex + 1 === moment.steps.length) {
          setGameCleared(true);
          return previousStepIndex;
        }
  
        return previousStepIndex + 1;
      });
    }, 1500);
  };

  const { multiLanguageString, getString } = useContext(LanguageContext);

  return <div className={`Moment game ${`type-${moment.type}`} ${visible ? "visible" : "invisible"}`}><div className="total-wrapper">
    <div className="exit-wrapper">
      <CustomButton onClick={exit} aria-label={multiLanguageString(getString("momentExit"))} postIcon={<ExitSvg />} />
    </div>
    <><ImageTapGameStep gameAnalyticsName={moment.analyticsName} stepIndex={stepIndex} step={moment.steps[stepIndex]} congratulate={moment.congratulate || false} {...{ characters, goNextStep, gameCleared }} /></>
  </div>
  </div>;
};
