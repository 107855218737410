import { z } from "zod";
import { itemSchema } from "../../../../backend/types/item";
import { museumSchema } from "../../../../backend/types/museum";

import "./index.scss";
import { LanguageContext } from "../../utils/multiLanguageString";
import { useContext, useEffect, useState } from "react";
import { makeRemoteCallHandler } from "../../libProcedureWire";
import { Endpoints } from "../../../../backend";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomButton } from "../../components/CustomAButton/CustomButton";
import { BackSvg } from "../../components/BackSvg";
import { ItemModal } from "../../components/ItemModal";
import { roomSchema } from "../../../../backend/types/room";
import { mediaSchema } from "../../../../backend/types/media";
import ga4 from "react-ga4";

export const MuseumItems = ({ 
  items, medias, museums, room, unplaceItem,
}: {
  items: z.infer<typeof itemSchema>[], 
  medias: z.infer<typeof mediaSchema>[],
  museums: z.infer<typeof museumSchema>[], 
  room: z.infer<typeof roomSchema.shape.items>, 
  unplaceItem: ({ item }: { item: z.infer<typeof itemSchema> }) => void
}) => {
  type MuseumId = (typeof museums)[number]["_id"];
  type Item = (typeof items)[number] & { inCollection: boolean };
  type Museum = (typeof museums)[number];

  const [museumItems, setMuseumItems] = useState<{ [key: MuseumId]: Item[] }>();
  const [museum, setMuseum] = useState<Museum>();
  const [openItem, setOpenItem] = useState<(typeof items)[number] | undefined>();

  const remoteCall = makeRemoteCallHandler<Endpoints>();

  const navigate = useNavigate();
  const location = useLocation();

  const { museumId } = useParams();

  useEffect(() => {
    if (museum) {
      ga4.send({ hitType: "pageview", page: "museumItems", title: "museumItems", museum: museum.analyticsName });
    }
  }, [museum]);

  useEffect(() => {
    if (museumId) {
      setMuseum(museums.find((singleMuseum) => singleMuseum._id === museumId));
    }
  }, [museumId]);

  useEffect(() => {
    (async () => {
      try {
        const currentCollectionItems = (await remoteCall("collectionRead", {})).items;

        const currentMuseumItems = museums.reduce((cumulativeObject, currentMuseum) => {
          const singleMuseumItems = items
            .filter((singleItem) => singleItem.museums.includes(currentMuseum._id))
            .map((singleItem) => ({ ...singleItem, inCollection: currentCollectionItems.includes(singleItem._id) }));
  
          return { ...cumulativeObject, [currentMuseum._id]: [...singleMuseumItems] };
        }, {} as { [key: MuseumId]: Item[] });
  
        setMuseumItems(currentMuseumItems);
      } catch (error) {

      }
     
    })();
  }, [items]);

  const setOpenItemById = (id: string) => {
    const currentItem = items.find((singleItem) => singleItem._id === id);

    if (currentItem) setOpenItem(currentItem);
  };

  const closeItem = () => {
    setOpenItem(undefined);
  };

  const { multiLanguageString, getString } = useContext(LanguageContext);
  
  return (<>
    {museum && <div className="MuseumItems">
      <div className="header">
        <div className="exit-wrapper">
          <CustomButton kind="primary" preIcon={<BackSvg />} title={multiLanguageString(getString("collectionExit"))} onClick={() => {
            if (location?.state?.prevRoute) {
              navigate((location.state.prevRoute as Location)?.pathname || "/");
            } else {
              navigate("/");
            }
          }} />
        </div>
        <div className="title-wrapper">{multiLanguageString(museum.name)}</div>
      </div>
      {museumItems && <div className="list">
        {museumItems[museum._id].map((singleItem) => 
          <button key={singleItem._id} className={`item ${singleItem.inCollection ? "" : "notInCollection"}`} onClick={() => {if (singleItem.inCollection) setOpenItemById(singleItem._id);}} title={multiLanguageString(singleItem.name)}>
            {singleItem.image !== "" && <img alt="" src={singleItem.image} />}
          </button>)}
      </div>}
    </div>}
    <ItemModal museums={museums} item={openItem} close={closeItem} {...{ medias, room, unplaceItem }} />
  </>);
};
