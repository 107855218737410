import { z } from "zod";
import { itemSchema } from "../../../../backend/types/item";
import { museumSchema } from "../../../../backend/types/museum";

import "./index.scss";
import { LanguageContext } from "../../utils/multiLanguageString";
import { useContext, useEffect, useState } from "react";
import { makeRemoteCallHandler } from "../../libProcedureWire";
import { Endpoints } from "../../../../backend";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { CaretSvg } from "../../components/CaretSvg";
import { CustomButton } from "../../components/CustomAButton/CustomButton";
import { BackSvg } from "../../components/BackSvg";
import { ItemModal } from "../../components/ItemModal";
import { roomSchema } from "../../../../backend/types/room";
import { skinAssets } from "../../utils/skins/skinAssets";
import { CustomLink } from "../../components/CustomAButton/CustomLink";
import { ArrowSvg } from "../../components/ArrowSvg";
import { mediaSchema } from "../../../../backend/types/media";
import ga4 from "react-ga4";

export const Items = ({ 
  items, medias, museums, room, unplaceItem, chooseSkin,
}: {
  items: z.infer<typeof itemSchema>[], 
  medias: z.infer<typeof mediaSchema>[],
  museums: z.infer<typeof museumSchema>[], 
  room: { items: z.infer<typeof roomSchema>["items"], skins: z.infer<typeof roomSchema>["skins"] }, 
  unplaceItem: ({ item }: { item: z.infer<typeof itemSchema> }) => void
  chooseSkin: ({ place, skin }: { place: string, skin: string }) => void
}) => {
  type MuseumId = (typeof museums)[number]["_id"];
  type Item = (typeof items)[number] & { inCollection: boolean };

  const [museumItems, setMuseumItems] = useState<{ [key: MuseumId]: Item[] }>();
  const [obtainedMuseumItems, setObtainedMuseumItems] = useState<{ [key: MuseumId]: Item[] }>();
  const [collectionSkins, setCollectionSkins] = useState<string[]>([]);
  const [openItem, setOpenItem] = useState<(typeof items)[number] | undefined>();
  const [activeSection, setActiveSection] = useState<"objects" | "skins">("objects");
  const [changedSkin, setChangedSkin] = useState(false);

  const remoteCall = makeRemoteCallHandler<Endpoints>();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (activeSection === "objects") {
      ga4.send({ hitType: "pageview", page: "/collection/items#objects", title: "collection-items-objects" });
    } else if (activeSection === "skins") {
      ga4.send({ hitType: "pageview", page: "/collection/items#skins", title: "collection-items-skins" });

    }
  }, [activeSection]);

  useEffect(() => {
    (async () => {
      try {
        const collection = (await remoteCall("collectionRead", {}));
        const currentCollectionItems = collection.items;
        const currentCollectionSkins = collection.skins;
        setCollectionSkins(currentCollectionSkins);

        const currentMuseumItems = museums.reduce((cumulativeObject, currentMuseum) => {
          const singleMuseumItems = items
            .filter((singleItem) => singleItem.museums.includes(currentMuseum._id))
            .map((singleItem) => ({ ...singleItem, inCollection: currentCollectionItems.includes(singleItem._id) }));
  
          return { ...cumulativeObject, [currentMuseum._id]: [...singleMuseumItems] };
        }, {} as { [key: MuseumId]: Item[] });
  
        setMuseumItems(currentMuseumItems);
  
        const currentObtainedMuseumItems = Object.keys(currentMuseumItems).reduce((cumulativeObject, currentMuseumId) => {
          return { ...cumulativeObject, [currentMuseumId]: currentMuseumItems[currentMuseumId].filter((singleItem) => singleItem.inCollection) };
        }, {} as { [key: MuseumId]: Item[] } );
  
        setObtainedMuseumItems(currentObtainedMuseumItems);
      } catch (error) {

      }
    })();
  }, [items]);

  const setOpenItemById = (id: string) => {
    const currentItem = items.find((singleItem) => singleItem._id === id);

    if (currentItem) setOpenItem(currentItem);
  };

  const closeItem = () => {
    setOpenItem(undefined);
  };

  const { multiLanguageString, getString } = useContext(LanguageContext);

  const TrophyList = () => {
    return <>
      {typeof location?.state?.position !== "undefined" && <div className="placeCTA">{multiLanguageString(getString("collectionPlaceCTA"))}</div>}
      {obtainedMuseumItems && museumItems && <div className="list">
        {museums.map((singleMuseum, index) => {
          return typeof location?.state?.position === "undefined" || (typeof location?.state?.position !== "undefined" && obtainedMuseumItems[singleMuseum._id].some((singleItem) => singleItem.positions.includes(location?.state?.position)))
            ? <div key={index} className="single">
              {typeof location?.state?.position === "undefined" && <Link to={`/collection/museumItems/${singleMuseum._id}`} state={{ prevRoute: location }} className="header">
                <div className="name">{multiLanguageString(singleMuseum.name)}</div>
                {<div className="counter">
                  {obtainedMuseumItems[singleMuseum._id].length}
                  {"/"}
                  {museumItems[singleMuseum._id].length}
                  <CaretSvg />
                </div>}
              </Link>}
              {typeof location?.state?.position !== "undefined" && <div className="header">
                <div className="name">{multiLanguageString(singleMuseum.name)}</div>
              </div>}
              <div className="items">
                {obtainedMuseumItems[singleMuseum._id].length > 0 
                && <div className="scrollable">
                  {obtainedMuseumItems[singleMuseum._id].map((singleItem) => 
                    (typeof location?.state?.position === "undefined" || singleItem.positions.includes(location?.state?.position)) && <button key={singleItem._id} className="item" onClick={() => {setOpenItemById(singleItem._id);}} title={multiLanguageString(singleItem.name)}>
                      {singleItem.image !== "" && <img alt="" src={singleItem.image} />}
                    </button>,
                  )}
                </div>
                }
                {obtainedMuseumItems[singleMuseum._id].length === 0 && <div className="empty">
                  {multiLanguageString(getString("collectionEmptyPre"))}
                  <br />
                  <Link to={`/museum/${singleMuseum._id}`}>{multiLanguageString(getString("collectionEmptyVisit"))}</Link>{" "}
                  {multiLanguageString(getString("collectionEmptyPost"))}
                </div>}
              </div>
            </div>
            : null;
        })}
      </div>}</>;
  };

  const SkinList = () => {

    return <>
      <p className="purpose">{multiLanguageString(getString("collectionSkinPurpose"))}</p>
      <div className="list">
        {Object.keys(skinAssets).map((layer) => <div key={layer} className="single">
          <div className="header">
            <div className="name">{
              `${layer === "wall" ? multiLanguageString([{ lang: "it", string: "Muro" }, { lang: "en", string: "Wall" }]) : ""}
              ${layer === "floor" ? multiLanguageString([{ lang: "it", string: "Pavimento" }, { lang: "en", string: "Floor" }]) : ""}
              ${layer === "furniture" ? multiLanguageString([{ lang: "it", string: "Mobili" }, { lang: "en", string: "Furniture" }]) : ""}
              ${layer === "decoration" ?  multiLanguageString([{ lang: "it", string: "Decorazioni" }, { lang: "en", string: "Decoration" }]) : ""}`
            }</div>
          </div>
          <div className="items">
            <div className="scrollable">
              {Object.keys(skinAssets[layer as keyof typeof skinAssets]).filter((skinItemName) => collectionSkins.includes(skinItemName)).map((skinItemName) => {
                //@ts-expect-error
                const itemImage = skinAssets[layer][skinItemName];
                return <button 
                  key={`${skinItemName} ${layer}`} 
                  onClick={() => {setChangedSkin(true); chooseSkin({ place: layer, skin: skinItemName });}}
                  /* @ts-expect-error */
                  className={`item skin ${room.skins[layer] === skinItemName ? "active" : ""}`}
                >
                  <div className={`zoomed ${layer}`} style={{ backgroundImage: `url("${itemImage}")` }} />
                </button>;
              })}
            </div>
          </div>
        </div>)}
      </div>
      <div className={`go-wrapper ${changedSkin ? "active" : ""}`}>
        <CustomLink to="/collection" postIcon={<ArrowSvg />}>{multiLanguageString(getString("collectionSkinSeeResult"))}</CustomLink>
      </div>
    </>;
  };
  
  return (
    <>
      <div className="Items">
        <div className="header">
          <div className="exit-wrapper">
            <CustomButton kind="primary" preIcon={<BackSvg />} title={multiLanguageString(getString("collectionExit"))} onClick={() => {
              if (location?.state?.prevRoute) {
                navigate((location.state.prevRoute as Location)?.pathname || "/");
              } else {
                navigate("/");
              }
            }} />
          </div>
          <div className="title-wrapper">{multiLanguageString(getString("collectionItemsTitle"))}</div>
        </div>
        {typeof location?.state?.position === "undefined" && <div className="tabSelector">
          <button className={`tab ${activeSection === "objects" ? "active" : ""}`} onClick={() => {setActiveSection("objects");}}>{multiLanguageString(getString("collectionObjectsTab"))}</button>
          <button className={`tab ${activeSection === "skins" ? "active" : ""}`} onClick={() => {setActiveSection("skins");}}>{multiLanguageString(getString("collectionSkinsTab"))}</button>
        </div>}
        {activeSection === "objects" && <TrophyList />}
        {activeSection === "skins" && <SkinList />}
      </div>
      <ItemModal museums={museums} item={openItem} close={closeItem} {...{ room: room.items, medias, unplaceItem }} />
    </>
  );
};
