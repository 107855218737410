import { LanguageContext } from "../../../utils/multiLanguageString";
import { CustomButton } from "../../../components/CustomAButton/CustomButton";

import { MomentProps } from "..";
import { useContext, useEffect, useState } from "react";
import { ArrowSvg } from "../../../components/ArrowSvg";
import { makeRemoteCallHandler } from "../../../libProcedureWire";
import { Endpoints } from "../../../../../backend";
import ga4 from "react-ga4";

export const ItemGivenMoment = ({ moment, goForward, visible, characters, items, museums }: MomentProps) => {
  if (moment.type !== "itemGiven") return <></>;
  const character = characters.find((singleCharacter) => singleCharacter._id === moment.characterId);

  const item = items.find((singleItem) => singleItem._id === moment.itemId);

  const [previousCollection, setPreviousCollection] = useState<string[] | undefined>();
  const remoteCall = makeRemoteCallHandler<Endpoints>();

  useEffect(() => {
    if (item) {
      ga4.event("item_given", {
        item_name: item.analyticsName,
      }); 
    }
  }, [item]);

  useEffect(() => {
    (async () => {
      if (!item) return;

      try {
        const currentCollectionItems = (await remoteCall("collectionRead", {})).items;
        setPreviousCollection(currentCollectionItems);
        const newCollectionItems = currentCollectionItems.includes(item._id) ? currentCollectionItems : [...currentCollectionItems, item._id];
        await remoteCall("collectionSave", { items: newCollectionItems });
      } catch (e) {

      }
    })();
  }, []);

  const { multiLanguageString, getString } = useContext(LanguageContext);
  
  return <div className={`Moment ${`type-${moment.type}`} ${visible ? "visible" : "invisible"}`}><div className="total-wrapper">
    
    {character && moment.dialogueText && <div className="balloon-wrapper">
      {character && <div className="name">{multiLanguageString(character.name)}</div>}
      {moment.dialogueText && <div className="text" dangerouslySetInnerHTML={{ __html: multiLanguageString(moment.dialogueText) }} />}
    </div>}
    <div className="bottom-wrapper">
      {item && <>
        <div className="image-wrapper">
          {item.image && <img src={item.image}  alt="" />}
        </div>
        <div className="text-wrapper">
          <div className="title-wrapper">
            {multiLanguageString(item.name)}
          </div>
          <div className="description-wrapper">
            {previousCollection && !previousCollection.includes(moment.itemId)
              ? multiLanguageString(getString("momentItemAdded"))
              : multiLanguageString(getString("momentItemAlreadyPresent"))}
          </div>
        </div>
        {item.museums.length > 1 && <div className="text-wrapper link-wrapper">
          {multiLanguageString(getString("linkToOtherMuseum"))}{" "}
          {item.museums.map((singleMuseumId) => multiLanguageString(museums.find((singleMuseum) => singleMuseum._id === singleMuseumId)?.name)).join(", ")}{"."}
          <br />
          <br />
          {multiLanguageString(getString("linkExploreCTA"))}
        </div>}
      </>}
      <div className="actions-wrapper">
        <CustomButton postIcon={<ArrowSvg />} onClick={() => {
          goForward();
        }}>
          {multiLanguageString(getString("momentContinue"))}
        </CustomButton>
      </div>
    </div>
  </div>
  </div>;
};
