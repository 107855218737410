import { LanguageContext } from "../../../utils/multiLanguageString";
import { CustomButton } from "../../../components/CustomAButton/CustomButton";

import { MomentProps } from "..";
import { ExitSvg } from "../../../components/ExitSvg";
import { useContext, useEffect, useState } from "react";
import { RightSvg } from "../../../components/RightSvg";
import { WrongSvg } from "../../../components/WrongSvg";
import ga4 from "react-ga4";

const ErrorIcon = ({ top, left }: { top: number, left: number }) => {
  return <span className="ErrorIcon" style={{ top, left }}>
    <WrongSvg />
  </span>;
};

const RightIcon = ({ top, left }: { top: number, left: number }) => {
  return <span className="RightIcon" style={{ top, left }}>
    <RightSvg />
  </span>;
};

const AnswerBox = (
  { answer, index, tryAnswer, answerFormat }: 
  {
    answer: Extract<MomentProps["moment"], { type: "multipleChoiceGame" }>["questions"][number]["answers"][number],
    index: number,
    tryAnswer: (index: number) => boolean,
    answerFormat: Extract<MomentProps["moment"], { type: "multipleChoiceGame" }>["questions"][number]["answerFormat"],
  }) => {
  const [errorSpots, setErrorSpots] = useState<{ top: number, left: number }[]>([]);
  const [rightSpots, setRightSpots] = useState<{ top: number, left: number }[]>([]);

  useEffect(() => {
    setErrorSpots([]);
    setRightSpots([]);
  }, [answer]);

  const addErrorSpot = ({ top, left }: { top: number, left: number }) => {
    setErrorSpots((previousErrorSpots) => [...previousErrorSpots, { top, left }]);
  };

  const addRightSpot = ({ top, left }: { top: number, left: number }) => {
    setRightSpots((previousRightSpots) => [...previousRightSpots, { top, left }]);
  };

  const { multiLanguageString } = useContext(LanguageContext);
  
  return <div 
    className={`AnswerBox format-${answerFormat}`}
    onClick={(event) => {
      const result = tryAnswer(index);
    
      if (!result) {
        /* @ts-expect-error */
        addErrorSpot({ top: event.nativeEvent.layerY || event.nativeEvent.offsetY, left: event.nativeEvent.layerX || event.nativeEvent.offsetX });
      } else {
        /* @ts-expect-error */
        addRightSpot({ top: event.nativeEvent.layerY || event.nativeEvent.offsetY, left: event.nativeEvent.layerX || event.nativeEvent.offsetX });
      }
    }} 
  >
    {answer.text &&
     <div className="text" dangerouslySetInnerHTML={{ __html: multiLanguageString(answer.text) }} />
    }
    {answer.image && answer.image.length > 0 &&
     <div className="image">
       <img src={multiLanguageString(answer.image)} alt="" />
     </div>
    }
    {errorSpots.map(({ left, top }, errorIndex) => <ErrorIcon key={`wrong${errorIndex}`} {...{ left, top }} />)}
    {rightSpots.map(({ left, top }, rightIndex) => <RightIcon key={`right${rightIndex}`} {...{ left, top }} />)}
  </div>;
};

export const MultipleChoiceGameMoment = ({ moment, goForward, visible, characters, exit }: MomentProps) => {
  if (moment.type !== "multipleChoiceGame") return <></>;

  const [forcedInvisibility, setForcedInvisibility] = useState<boolean>(false);

  const [rightAnswers, setRightAnswers] = useState<number>(0);
  const [gameCleared, setGameCleared] = useState<boolean>(false);

  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [question, setQuestion] = useState<Extract<MomentProps["moment"], { type: "multipleChoiceGame" }>["questions"][number]>(moment.questions[0]);

  const [character, setCharacter] = useState<typeof characters[number] | undefined>();

  const [locked, setLocked] = useState<boolean>(false);

  useEffect(() => {
    setGameCleared(false);
    
    setRightAnswers(0);
    setQuestionIndex(0);
    setCharacter(undefined);

    const forcedInvisibilityTimeout = setTimeout(() => {
      setForcedInvisibility(false);
    }, 1600);

    return () => {clearTimeout(forcedInvisibilityTimeout);};
  }, [moment]);

  useEffect(() => {
    if (questionIndex === moment.questions.length) {
      setGameCleared(true);
      return;
    }

    const foundQuestion = moment.questions[questionIndex];
    setQuestion(foundQuestion);

    const foundCharacter = characters.find((singleCharacter) => singleCharacter._id === foundQuestion?.characterId);
    setCharacter(foundCharacter);

  }, [questionIndex, moment]);

  const tryAnswer = (answerIndex: number) => {
    if (question.answers?.[answerIndex]?.right ) {
      setLocked(true);
      setRightAnswers((previousRightAnswers) => previousRightAnswers + 1);

      ga4.event("game_step_cleared", {
        game: moment.analyticsName,
        step_number: `step-${questionIndex}`,
      }); 

      setTimeout(() => {
        setForcedInvisibility(true);
      }, 1000);

      setTimeout(() => {
        setQuestionIndex((previousQuestionIndex) => previousQuestionIndex + 1);
        setLocked(false);
      }, 1500);

      setTimeout(() => {
        setForcedInvisibility(false);
      }, 1600);

      return true;
    }

    if (question.answers.length <= 2) {
      setLocked(true);
      setTimeout(() => {
        setForcedInvisibility(true);
      }, 1000);

      setTimeout(() => {
        setQuestionIndex((previousQuestionIndex) => previousQuestionIndex + 1);
        setLocked(false);
      }, 1500);

      setTimeout(() => {
        setForcedInvisibility(false);
      }, 1600);
    }

    return false;
  };

  useEffect(() => {
    if (gameCleared) {
      const clearGameTimeout = setTimeout(() => {
        if (!moment.atLeastOneRight || (moment.atLeastOneRight && rightAnswers >= 1)) {
          setQuestionIndex(0);
          setRightAnswers(0);
          setGameCleared(false);
          goForward();
        } else {
          setQuestionIndex(0);
          setRightAnswers(0);
          setGameCleared(false);
        }
      }, (moment.congratulate || (moment.atLeastOneRight && rightAnswers === 0)) ? 1500 : 200);

      return () => {clearTimeout(clearGameTimeout);};
    }
  }, [gameCleared]);

  const { multiLanguageString, getString } = useContext(LanguageContext);
  
  return <div className={`Moment game ${`type-${moment.type}`} ${visible ? "visible" : "invisible"}`}><div className="total-wrapper">
    <div className="exit-wrapper">
      <CustomButton onClick={exit} aria-label={multiLanguageString(getString("momentExit"))} postIcon={<ExitSvg />} />
    </div>
    <div className={`main-wrapper ${forcedInvisibility || !visible ? "" : "visible"} ${locked ? "locked" : ""}`}>
      {question && <>
        {character && question.dialogueText && <div className="balloon-wrapper">
          {character && <div className="name">{multiLanguageString(character.name)}</div>}
          {question.dialogueText && <div className="text" dangerouslySetInnerHTML={{ __html: multiLanguageString(question.dialogueText as { string: string; lang: "it" | "en"; }[]) }} />}
        </div>}
        <div className={`bottom-wrapper ${(gameCleared) ? "game-cleared" : ""}`}>
          <div className={`game-wrapper ${questionIndex === moment.questions.length ? "" : "visible"}`}>
            {question.image && <div className={`image-wrapper ${question.hideShadow ? "hide-shadow" : ""}`}>
              <img 
                src={multiLanguageString(question.image as { string: string; lang: "it" | "en"; }[])} 
                alt={
                  question.alternativeText 
                    ? multiLanguageString(question.alternativeText as { string: string; lang: "it" | "en"; }[])
                    : ""
                }
              />
            </div>}
            <div className="answer-list-wrapper">
              {question.answers.map((singleAnswer, index) => <AnswerBox key={index} answer={singleAnswer} answerFormat={question.answerFormat} {...{ index, tryAnswer }} />)}
            </div>
          </div>
          {(gameCleared && moment.congratulate) && <div className="game-congratulations">{multiLanguageString(getString("momentGameCongratulations"))}</div>}
          {(gameCleared && moment.atLeastOneRight && rightAnswers === 0) && <div className="game-congratulations red">{multiLanguageString(getString("momentGameRetry"))}</div>}
        </div>
      </>}
    </div>
  </div>
  </div>;
};
