import { useContext } from "react";
import { MomentProps } from "..";
import { ArrowSvg } from "../../../components/ArrowSvg";
import { CustomButton } from "../../../components/CustomAButton/CustomButton";
import { ExitSvg } from "../../../components/ExitSvg";
import { LanguageContext } from "../../../utils/multiLanguageString";

export const UnknownMoment = ({ moment, goForward, visible, exit }: MomentProps) => {
  const { multiLanguageString, getString } = useContext(LanguageContext);
  
  return (<div className={`Moment ${`type-${moment.type}`} ${visible ? "visible" : "invisible"}`}>
    <div className="exit-wrapper">
      <CustomButton onClick={exit} aria-label={multiLanguageString(getString("momentExit"))} postIcon={<ExitSvg />} />
    </div>
    <div>
      <div>Error</div>
      <div className="actions-wrapper">
        <CustomButton onClick={goForward} postIcon={<ArrowSvg />}>
          {multiLanguageString(getString("momentContinue"))}
        </CustomButton>
      </div>
    </div>
  </div>);
};
