import { useContext, useEffect, useState } from "react";
import { User } from "../../App";
import { BottomNavigation } from "../../components/BottomNavigation";
import { Checkbox } from "../../components/Checkbox";
import { CustomButton } from "../../components/CustomAButton/CustomButton";
import { ExitSvg } from "../../components/ExitSvg";
import { Input } from "../../components/Input";
import { Select } from "../../components/Select";

import { LanguageContext } from "../../utils/multiLanguageString";

import "./index.scss";
import { makeRemoteCallHandler } from "../../libProcedureWire";
import { Endpoints } from "../../../../backend";
import { useNavigate } from "react-router-dom";
import ga4 from "react-ga4";

export const Profile = ({ user, setUser, fixMode }: { user: User, setUser: (value: React.SetStateAction<User | undefined>) => void, fixMode?: boolean }) => {
  const inputColors = {
    labelColor:"#FD671A", textColor:"#632917", activeColor:"#F4C10C",
  };

  const { multiLanguageString, getString, currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  
  const [formUser, setFormUser] = useState<User>(
    {
      privileges: user.privileges,
      age: user.age || "",
      displayName: user.displayName || "",
      email: user.email || "",
      familyName: user.familyName || "",
      givenName: user.givenName || "",
      newsletter: user.newsletter || false,
      policy: user.policy || false,
      _id: user._id || "",
    },
  );

  const emailAlreadyGiven = !!(user.email && user.email !== "");

  const [formErrors, setFormErrors] = useState<{ [Key in keyof User]?: string }>({});

  const setError = (key: keyof User, error: string) => {
    setFormErrors((prev) => ({ ...prev, [key]: error }));
  };

  const removeError = (key: keyof User) => {
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[key];
      return newErrors;
    });
  };

  const checkForm = () => {
    let valid = true;
    //Check form
    ["age", "email", "familyName", "givenName", "policy"].forEach((key) => {
      if (!formUser[key as keyof User] || formUser[key as keyof User] === "") {
        setError(key as keyof User, multiLanguageString(getString("formRequired")));
        valid = false;
      } else {
        removeError(key as keyof User);
      }
    });

    return valid;
  };

  const remoteCall = makeRemoteCallHandler<Endpoints>();
  const navigate = useNavigate();

  const submitForm = async () => {
    const valid = checkForm();
    
    if (valid) {
      const displayName = `${formUser.givenName} ${formUser.familyName}`;
      const newUser = { ...formUser, displayName };
      
      try {
        await remoteCall("meUpdate", { user: newUser });
        setUser(newUser);
        if (!fixMode) navigate("/");
      } catch (error) {
        console.error(error);
      }

    } else {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    ga4.send({ hitType: "pageview", page: "/profile", title: "profile" });
  }, []);

  useEffect(() => {
    checkForm();
  }, [formUser, currentLanguage]);

  const logout = () => {
    localStorage.removeItem("sesameToken");
    location.reload();
  };

  const deleteAccount = async () => {
    const promptResult = confirm(multiLanguageString(getString("profileDeleteLastWarning")));

    if (promptResult) {
      try {
        await remoteCall("meDelete", {});
        logout();
      } catch (error) {
        console.error(error);
      }
    }
  };


  return <>
    {user && <div className={`Profile ${fixMode ? "fixMode" : ""}`}>
      <div className="wrapper">
        {!fixMode && <div className="logout-wrapper">
          <CustomButton onClick={logout} postIcon={<ExitSvg />}>{multiLanguageString(getString("logout"))}</CustomButton>
        </div>}
        <div className="section sectionInfo">
          <h2>{fixMode ? multiLanguageString(getString("profileCompleteTitle")) : multiLanguageString(getString("profileInfo"))}</h2>
          <Input value={formUser.givenName} error={formErrors.givenName} label={multiLanguageString(getString("profileName"))} {...inputColors} onChange={(event) => {setFormUser((previousFormUser) => ({ ...previousFormUser, givenName: event.target.value }));}} />
          <Input value={formUser.familyName} error={formErrors.familyName} label={multiLanguageString(getString("profileSurname"))} {...inputColors} onChange={(event) => {setFormUser((previousFormUser) => ({ ...previousFormUser, familyName: event.target.value }));}} />
          <Input value={formUser.email} disabled={emailAlreadyGiven} error={formErrors.email} type="email" label={multiLanguageString(getString("profileEmail"))} {...inputColors} onChange={(event) => {setFormUser((previousFormUser) => ({ ...previousFormUser, email: event.target.value }));}} />
          <Select error={formErrors.age} label={multiLanguageString(getString("profileAge"))} {...inputColors} value={formUser.age || "undefined"} onChange={(event) => {setFormUser((previousFormUser) => ({ ...previousFormUser, age: event.target.value }));}}>
            <option disabled value={"undefined"}>{multiLanguageString(getString("profileChooseAge"))}</option>
            <option value={"<18"}>&lt; 18</option>
            <option value={"18-24"}>18 - 24</option>
            <option value={"25-34"}>25 - 34</option>
            <option value={"35-44"}>35 - 44</option>
            <option value={"45-54"}>45 - 54</option>
            <option value={"55-64"}>55 - 64</option>
            <option value={">64"}>&gt; 64</option>
            <option value={"na"}>{multiLanguageString(getString("profileAgeRefuse"))}</option>
          </Select>
          <Select 
            value={currentLanguage}
            onChange={(event) => {
              const value = event.target.value;
              setCurrentLanguage(value);
            }} 
            label={multiLanguageString(getString("profileLanguage"))}
            {...inputColors}
          >
            <option value="it">{multiLanguageString(getString("languageItalian"))}</option>
            <option value="en">{multiLanguageString(getString("languageEnglish"))}</option>
          </Select>
        </div>
        <div className="section sectionPrivacy">
          <h2>{multiLanguageString(getString("profilePrivacyTitle"))}</h2>
          <Checkbox error={formErrors.policy} checked={formUser.policy} onChange={(event) => {setFormUser((previousFormUser) => ({ ...previousFormUser, policy: event.target.checked }));}} label={<span dangerouslySetInnerHTML={{ __html: multiLanguageString(getString("profilePrivacyRead_richtext")) }}></span>} {...{ ...inputColors, textColor: "black" }} />
          <Checkbox checked={formUser.newsletter} onChange={(event) => {setFormUser((previousFormUser) => ({ ...previousFormUser, newsletter: event.target.checked }));}} label={<span dangerouslySetInnerHTML={{ __html: multiLanguageString(getString("profileNewsletter")) }}></span>} {...{ ...inputColors, textColor: "black" }} />
        </div>
        <div className="section sectionSave">
          <CustomButton onClick={submitForm}>{multiLanguageString(getString("profileSave"))}</CustomButton>
          {fixMode && <div><br/>
            <CustomButton onClick={logout} kind="text">{multiLanguageString(getString("orElseLogout"))}</CustomButton>
          </div>}
        </div>
        {!fixMode && <div className="section sectionAbout">
          <h2>{multiLanguageString(getString("profileAboutTitle"))}</h2>
          <p>{multiLanguageString(getString("profileAboutCopyright"))}</p>
          <p>{multiLanguageString(getString("profileAboutPI"))}</p>
          <p dangerouslySetInnerHTML={{ __html: multiLanguageString(getString("profileAboutCookie_richtext")) }}></p>
          <p dangerouslySetInnerHTML={{ __html: multiLanguageString(getString("profileAboutPrivacy_richtext")) }}></p>
        </div>}
        {!fixMode && <div className="section sectionDelete">
          <h2>{multiLanguageString(getString("profileDeleteTitle"))}</h2>
          <p>{multiLanguageString(getString("profileDeleteText"))}</p>
          <CustomButton onClick={deleteAccount} kind="outline">{multiLanguageString(getString("profileDeleteCTA"))}</CustomButton>
        </div>}
      </div>

      {!fixMode && <BottomNavigation activeItem="profile" />}
    </div>}
  </>;
};
