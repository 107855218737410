import default_decoration from "./img/default_decoration.png";
import default_floor from "./img/default_floor.png";
import default_furniture from "./img/default_furniture.png";
import default_wall from "./img/default_wall.png";
import seta_decoration from "./img/seta_decoration.png";
import seta_floor from "./img/seta_floor.png";
import seta_furniture from "./img/seta_furniture.png";
import seta_wall from "./img/seta_wall.png";
import barca_decoration from "./img/barca_decoration.png";
import barca_floor from "./img/barca_floor.png";
import barca_furniture from "./img/barca_furniture.png";
import barca_wall from "./img/barca_wall.png";
import ghisallo_decoration from "./img/ghisallo_decoration.png";
import ghisallo_floor from "./img/ghisallo_floor.png";
import ghisallo_furniture from "./img/ghisallo_furniture.png";
import ghisallo_wall from "./img/ghisallo_wall.png";
import extra_decoration from "./img/extra_decoration.png";
import extra_floor from "./img/extra_floor.png";
import extra_furniture from "./img/extra_furniture.png";
import extra_wall from "./img/extra_wall.png";
import default_img from "./img/default.png";
import seta_img from "./img/seta.png";
import barca_img from "./img/barca.png";
import ghisallo_img from "./img/ghisallo.png";
import extra_img from "./img/extra.png";

export const skinAssets = {
  floor: {
    default: default_floor,
    seta: seta_floor,
    barca: barca_floor,
    ghisallo: ghisallo_floor,
    additional2: extra_floor,
  },
  wall: {
    default: default_wall,
    seta: seta_wall,
    barca: barca_wall,
    ghisallo: ghisallo_wall,
    additional1: extra_wall,
  },
  furniture: {
    default: default_furniture,
    seta: seta_furniture,
    barca: barca_furniture,
    ghisallo: ghisallo_furniture,
    additional5: extra_furniture,
  },
  decoration: {
    default: default_decoration,
    seta: seta_decoration,
    barca: barca_decoration,
    ghisallo: ghisallo_decoration,
    additional10: extra_decoration,
  },
} as const;

export const skinCeilings = {
  default: "linear-gradient(99.1deg, #DCF1F2 0%, #23B5C9 76%, #0F8EB7 100%)",
  seta: "linear-gradient(180deg, #FDFBF5 0%, #FAF3DD 19.79%, #FAF3DD 80.73%, #F7E9C0 100%)",
  barca: "linear-gradient(99.1deg, #DCF1F2 0%, #23B5C9 76%, #0F8EB7 100%)",
  ghisallo: "linear-gradient(180deg, #0F8EB7 0%, #23B5C9 100%)",
  additional1: "linear-gradient(180deg, #FDFBF5 0%, #FAF3DD 19.79%, #FAF3DD 80.73%, #F7E9C0 100%)",
} as const;

export const skinParameters = {
  default: {
    name: [
      {
        lang: "it",
        string: "Skin base",
      },
      {
        lang: "en",
        string: "Base skin",
      },
    ],
    image: default_img,
  },
  seta: {
    name: [
      {
        lang: "it",
        string: "Skin del Museo della Seta",
      },
      {
        lang: "en",
        string: "Como Silk Museum Skin",
      },
    ],
    image: seta_img,
  },
  barca: {
    name: [
      {
        lang: "it",
        string: "Skin del Museo Barca Lariana",
      },
      {
        lang: "en",
        string: "Lake Como Vintage Boats Museum Skin",
      },
    ],
    image: barca_img,
  },
  ghisallo: {
    name: [
      {
        lang: "it",
        string: "Skin del Museo del Ciclismo Madonna del Ghisallo",
      },
      {
        lang: "en",
        string: "Madonna del Ghisallo Cycling Museum Skin",
      },
    ],
    image: ghisallo_img,
  },
  additional1: {
    name: [
      {
        lang: "it",
        string: "Skin Aggiuntiva",
      },
      {
        lang: "en",
        string: "Additional Skin",
      },
    ],
    image: extra_img,
  },
  additional2: {
    name: [
      {
        lang: "it",
        string: "Skin Aggiuntiva",
      },
      {
        lang: "en",
        string: "Additional Skin",
      },
    ],
    image: extra_img,
  },
  additional5: {
    name: [
      {
        lang: "it",
        string: "Skin Aggiuntiva",
      },
      {
        lang: "en",
        string: "Additional Skin",
      },
    ],
    image: extra_img,
  },
  additional10: {
    name: [
      {
        lang: "it",
        string: "Skin Aggiuntiva",
      },
      {
        lang: "en",
        string: "Additional Skin",
      },
    ],
    image: extra_img,
  },
};
