import React, { useEffect, useState } from "react";
import { makeRemoteCallHandler } from "./libProcedureWire";
import type { Endpoints } from "../../backend";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { z } from "zod";
import { museumSchema } from "../../backend/types/museum";
import { Home } from "./screens/Home";
import { Museum } from "./screens/Museum";
import { onboardingStationSchema, stationSchema } from "../../backend/types/station";
import { Station } from "./screens/Station";
import { characterSchema } from "../../backend/types/character";
import { itemSchema } from "../../backend/types/item";
import { Items } from "./screens/Items";
import { MuseumItems } from "./screens/MuseumItems";
import { LoadScript } from "@react-google-maps/api";
import { Login } from "./screens/Login";
import { Collection } from "./screens/Collection";
import { roomSchema } from "../../backend/types/room";
import { Profile } from "./screens/Profile";
import { UserType } from "../../backend/procedures/me";
import { LanguageWrapper } from "./utils/multiLanguageString";
import { pinpointSchema } from "../../backend/types/pinpoint";
import { OnboardingName } from "./onboardingStations";

import { ReactComponent as Loading } from "./loading.svg";

import "./App.scss";
import { QrScan } from "./screens/QrScan";
import { mediaSchema } from "../../backend/types/media";
import ga4 from "react-ga4";

ga4.initialize([
  {
    trackingId: "G-LN7SZHWL92",
    gaOptions: {
      send_page_view: false,
    },
  },
]);

export type User = UserType;
const remoteCall = makeRemoteCallHandler<Endpoints>();
export type RemoteCallHandler = typeof remoteCall;

function App() {
  const [logged, setLogged] = useState<boolean>(false);
  const [needsLogin, setNeedsLogin] = useState<boolean>(false);
  const [/* token*/, setToken] = useState<string>();
  const [user, setUser] = useState<User>();
  const [profileComplete, setProfileComplete] = useState<boolean>();
  const [museums, setMuseums] = useState<z.infer<typeof museumSchema>[]>();
  const [stations, setStations] = useState<z.infer<typeof stationSchema>[]>();
  const [pinpoints, setPinpoints] = useState<z.infer<typeof pinpointSchema>[]>();
  const [characters, setCharacters] = useState<z.infer<typeof characterSchema>[]>();
  const [items, setItems] = useState<z.infer<typeof itemSchema>[]>();
  const [medias, setMedias] = useState<z.infer<typeof mediaSchema>[]>();
  const [room, setRoom] = useState<{ items: z.infer<typeof roomSchema>["items"], skins: z.infer<typeof roomSchema>["skins"] }>();
  const [doneOnboardings, setDoneOnboardings] = useState<(OnboardingName)[]>();

  
  const [absoluteOnboardingStation, setAbsoluteOnboardingStation] = useState<z.infer<typeof onboardingStationSchema>>();
  const [collectionOnboardingStation, setCollectionOnboardingStation] = useState<z.infer<typeof onboardingStationSchema>>();
  const [homeOnboardingStation, setHomeOnboardingStation] = useState<z.infer<typeof onboardingStationSchema>>();
  const [home3OnboardingStation, setHome3OnboardingStation] = useState<z.infer<typeof onboardingStationSchema>>();

  useEffect(() => {
    (async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const urlToken = searchParams.get("token");

      if (urlToken && urlToken !== "") {
        localStorage.setItem("sesameToken", urlToken);
        window.history.replaceState({}, document.title, window.location.pathname);
      }

      const localToken = localStorage.getItem("sesameToken");
      if (localToken) {
        setLogged(true);
        setToken(localToken);
      } else {
        setNeedsLogin(true);
      }

      try {
        const [museumListCall, mediaListCall, onboardingStationListCall] = await Promise.all([
          remoteCall("museumList", {}),
          remoteCall("mediaList", {}),
          remoteCall("onboardingStationList", {}),
        ]);

        setMuseums(museumListCall.museums);
        setMedias(mediaListCall.medias);

        setAbsoluteOnboardingStation(onboardingStationListCall.onboardingStations.find((st) => st.nameId === "absolute"));
        setCollectionOnboardingStation(onboardingStationListCall.onboardingStations.find((st) => st.nameId === "collection"));
        setHomeOnboardingStation(onboardingStationListCall.onboardingStations.find((st) => st.nameId === "home"));
        setHome3OnboardingStation(onboardingStationListCall.onboardingStations.find((st) => st.nameId === "home3"));
      } catch (e) {
        console.log(e);
      }

      try {
        const onboardingReadCall = await remoteCall("onboardingRead", {});
        setDoneOnboardings(onboardingReadCall.onboardings);
      } catch (e) {
        setDoneOnboardings(["collection", "absolute", "home", "home2", "home3"]);
      }

      try {
        const meCall = await remoteCall("me", {});
        if (!meCall.user) return;
        setUser(meCall.user);
      } catch (error) {}

      try {
        const itemListCall = await remoteCall("itemList", { lightList: true });
        setItems(itemListCall.items);
      } catch (error) {}

      try {
        const [characterListCall, stationListCall, roomCall, pinpointListCall] = await Promise.all([
          remoteCall("characterList", {}),
          remoteCall("stationList", { lightList: true }),
          remoteCall("roomRead", {}),
          remoteCall("pinpointList", {}),
        ]);

        setCharacters(characterListCall.characters);
        setStations(stationListCall.stations);
        setRoom(roomCall);
        setPinpoints(pinpointListCall.pinpoints);

        const [fullStationListCall] = await Promise.all([
          remoteCall("stationList", {}),
        ]);
        setStations(fullStationListCall.stations);
      } catch (error) {}

      try {
        const itemListCall = await remoteCall("itemList", { });
        setItems(itemListCall.items);
      } catch (error) {}
    })();
  }, []);

  useEffect(() => {
    if (
      user 
      && user.givenName && user.givenName !== ""
      && user.familyName && user.familyName !== ""
      && user.email && user.email !== ""
      && user.age && user.age !== ""
      && user.policy && user.policy === true
    ) {
      setProfileComplete(true);
    } else if (user) {
      setProfileComplete(false);
    }
  }, [user]);

  const placeItem = ({ item, index }: { item: z.infer<typeof itemSchema>, index: number }) => {
    ga4.event("collection_place_item", {
      item: item.analyticsName,
    });

    setRoom((currentRoom) => {
      if (currentRoom) {
        const newRoomItems = currentRoom.items.filter((singleSpot) => singleSpot.index !== index && singleSpot.item !== item._id);

        newRoomItems.push({ index, item: item._id });

        (async () => {
          try {await remoteCall("roomSave", { items: newRoomItems });} catch (error) {}
        })();

        return { ...currentRoom, items: newRoomItems };
      }
    });
  };

  const unplaceItem = ({ item }: { item: z.infer<typeof itemSchema> }) => {
    ga4.event("collection_unplace_item", {
      item: item.analyticsName,
    });

    setRoom((currentRoom) => {
      if (currentRoom) {
        const newRoom = currentRoom.items.filter((singleSpot) => singleSpot.item !== item._id);
        
        (async () => {
          try { await remoteCall("roomSave", { items: newRoom });} catch (error) {}
        })();

        return { ...currentRoom, items: newRoom };
      }
    });
  };

  const chooseSkin = (({ place, skin }: { place: string, skin: string }) => {
    setRoom((currentRoom) => {
      if (currentRoom) {
        const newRoomSkins = { ...currentRoom.skins, [place]: skin };

        (async () => {
          try { await remoteCall("roomSave", { skins: newRoomSkins });} catch (error) {}
        })();

        return { ...currentRoom, skins: newRoomSkins };
      }
    });
  });

  const clearOnboarding = (onboarding: OnboardingName) => {
    (async () => {
      try { await remoteCall("onboardingSave", { onboarding });} catch (error) {}
    })();

    setDoneOnboardings(((prev) => {
      if (prev) {
        return [...prev, onboarding];
      }

      return [onboarding];
    }));
  };

  return (
    <LanguageWrapper>
      <div className="App">
        {logged && user && profileComplete === false ? 
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<Profile fixMode={true} {...{ user, setUser }} />} />
            </Routes>
          </BrowserRouter>
          : null}
        {logged && user && profileComplete && characters && doneOnboardings && absoluteOnboardingStation && collectionOnboardingStation && homeOnboardingStation && home3OnboardingStation
          ? (<LoadScript
            googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY as string}
            id="google-map-script"
            language="it"
            loadingElement={<div></div>}
          >
            {doneOnboardings.includes("absolute")
              ? <BrowserRouter>
                <Routes>
                  <Route path="/qrScan" element={<QrScan />} />
                  <Route path="/" element={
                    doneOnboardings.includes("home") 
                      ? (
                        doneOnboardings.includes("home2") 
                          ? (
                            doneOnboardings.includes("home3") 
                              ? <Home museums={museums} pinpoints={pinpoints} medias={medias} /> 
                              :  ((items && museums && medias) ? <Station onboardingStation={home3OnboardingStation} {...{ stations, characters, items, museums, medias, clearOnboarding }} /> : <span className="spinner"><Loading /></span>)
                          )
                          : <Home fog clearOnboarding={clearOnboarding} museums={museums} pinpoints={pinpoints} medias={medias} /> 
                      )
                      : ((items && museums && medias) ? <Station onboardingStation={homeOnboardingStation} {...{ stations, characters, items, museums, medias, clearOnboarding }} /> : <span className="spinner"><Loading /></span>)} />
                  <Route path="/museum/:museumId" element={(stations && museums) ? <Museum {...{ museums, stations }} /> : <span className="spinner"><Loading /></span>} />
                  <Route path="/station/:stationId" element={(stations && items && museums && medias) ? <Station {...{ stations, characters, items, museums, medias }} /> : <span className="spinner"><Loading /></span>} />
                  <Route path="/collection" element={room ? (
                    doneOnboardings.includes("collection") 
                      ? ((items && museums && medias) ? <Collection currentUserId={user._id} {...{ room, items, medias, placeItem, unplaceItem, museums }} /> : <span className="spinner"><Loading /></span>)
                      : ((items && museums && medias) ? <Station onboardingStation={collectionOnboardingStation} {...{ stations, characters, items, museums, medias, clearOnboarding }} /> : <span className="spinner"><Loading /></span>)
                  ) : <span className="spinner"><Loading /></span>} />
                  <Route path="/collection/items" element={room ? (
                    doneOnboardings.includes("collection") 
                      ? ((items && museums && medias) ? <Items {...{ items, medias, museums, unplaceItem, room, chooseSkin }} /> : <span className="spinner"><Loading /></span>) 
                      : ((items && museums && medias) ? <Station onboardingStation={collectionOnboardingStation} {...{ stations, characters, items, museums, medias, clearOnboarding }} /> : <span className="spinner"><Loading /></span>)
                  ) : <span className="spinner"><Loading /></span>} />
                  <Route path="/collection/museumItems/:museumId" element={room ? (
                    doneOnboardings.includes("collection") 
                      ? ((items && museums && medias) ? <MuseumItems  room={room.items} {...{ items, medias, museums, unplaceItem }} /> : <span className="spinner"><Loading /></span>)
                      : ((items && museums && medias) ? <Station onboardingStation={collectionOnboardingStation} {...{ stations, characters, items, museums, medias, clearOnboarding }} /> : <span className="spinner"><Loading /></span>)
                  ) : <span className="spinner"><Loading /></span>} />
                  <Route path="/profile" element={user ? <Profile {...{ user, setUser }} /> : <span className="spinner"><Loading /></span>} />
                  <Route path="/userCollection/:userId" element={((items && museums && medias) ? <Collection {...{ items, medias, placeItem, unplaceItem, museums }} /> : <span className="spinner"><Loading /></span>)} />
                </Routes>
              </BrowserRouter>
              :  <BrowserRouter>
                <Routes>
                  <Route path="*" element={((items && museums && medias) ? <Station onboardingStation={absoluteOnboardingStation} {...{ stations, characters, items, museums, medias, clearOnboarding }} /> : <span className="spinner"><Loading /></span>)} />
                </Routes>
              </BrowserRouter>
            }
          </LoadScript>
          ) 
          : (
            <BrowserRouter>
              <Routes>
                <Route path="/userCollection/:userId" element={items && museums && medias && <Collection {...{ items, medias, placeItem, unplaceItem, museums }} />} />
                <Route path="*" element={needsLogin ? <Login /> : null} />
              </Routes>
            </BrowserRouter>
          )
        }
      </div>
    </LanguageWrapper>
  );
}

export default App;
