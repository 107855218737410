import { z } from "zod";
import { museumSchema } from "../../../../backend/types/museum";
import { LanguageContext } from "../../utils/multiLanguageString";
import { Link, useLocation, useParams } from "react-router-dom";
import { stationSchema } from "../../../../backend/types/station";

import { CustomButton } from "../../components/CustomAButton/CustomButton";
import Collapsible from "react-collapsible";

import { ReactComponent as WebIcon } from "./web.svg";
import { ReactComponent as FbIcon } from "./fb.svg";
import { ReactComponent as IgIcon } from "./ig.svg";
import { ReactComponent as YtIcon } from "./yt.svg";
import { ReactComponent as LnIcon } from "./in.svg";

import { ReactComponent as LiveGoIcon } from "./live-go.svg";
import { ReactComponent as TrophyIcon } from "./trophy.svg";
import { ReactComponent as TicketIcon } from "./ticket.svg";

import "./index.scss";
import { CloseSvg } from "../../components/CloseSvg";
import { useContext, useEffect } from "react";
import ga4 from "react-ga4";

export const Museum = ({ museums, stations }: { museums: z.infer<typeof museumSchema>[], stations: z.infer<typeof stationSchema>[] }) => {
  const { museumId } = useParams();
  const museum = museums.find((singleMuseum) => singleMuseum._id === museumId);
  if (!museum) return null;

  const location = useLocation();

  let userFinishedStations: { [stationId: string]: string } = JSON.parse(localStorage.getItem("userFinishedStations") || "{}");
  userFinishedStations = { ...Object.keys(userFinishedStations).reduce((filteredStations, currentStationId) => {
    const currentStation = stations.find((singleStation) => singleStation._id === currentStationId);

    if (currentStation && currentStation.museumId === museumId) return { ...filteredStations, [currentStationId]: userFinishedStations[currentStationId] };
    
    return filteredStations;
  }, {} as { [stationId: string]: string }) };

  const museumStations = stations.filter((singleStation) => singleStation.museumId === museumId);

  const percent = Object.keys(userFinishedStations).length / museumStations.length * 100;
      
  let museumLatestUserStation: string | undefined = Object.keys(userFinishedStations).reduce((latestId, currentId) => {
    if (latestId === "") return currentId;

    const latestDate = new Date(userFinishedStations[latestId]);
    const currentDate = new Date(userFinishedStations[currentId]);

    if (currentDate > latestDate) return currentId;
    return latestId;
  }, "");

  if (!museumLatestUserStation) {
    const museumInitialStation = stations.find((singleStation) => singleStation.museumId === museum._id && singleStation.initial);
    museumLatestUserStation = museumInitialStation?._id;
  }

  const { multiLanguageString, getString } = useContext(LanguageContext);

  useEffect(() => {
    if (museum) {
      ga4.send({ hitType: "pageview", page: "/museum", title: "museum", museum: museum.analyticsName });
    }
  }, [museum]);
  
  return (
    <>
      {museum 
      && (
        <div className="Museum">
          <div className="whole-wrapper">
            <div className="header">
              <div className="left">
                {museum.photo && <div className="image">
                  <img src={museum.photo} alt="" />
                </div>}
                <div className="text">
                  <div className="name">{multiLanguageString(museum.name)}</div>
                  <div className="address">{multiLanguageString(museum.humanAddress)}</div>
                </div>
              </div>
              <div className="right">
                <Link to="/" aria-label={multiLanguageString(getString("museumExit"))}><CloseSvg /></Link>
              </div>
            </div>
            <div className="info-wrapper">
              <Collapsible
                trigger={<CustomButton>{multiLanguageString(getString("museumMoreInfo"))}</CustomButton>}
                triggerWhenOpen={<CustomButton>{multiLanguageString(getString("museumLessInfo"))}</CustomButton>}
                easing="ease-in-out"
              >
                <div className="info-list">
                  {museum.contacts && <div className="links-wrapper">
                    {museum.contacts.map((singleContact, index) => <a key={index} target="_blank" href={singleContact.url}>
                      {singleContact.type === "WEBSITE" && <WebIcon />}
                      {singleContact.type === "FB" && <FbIcon />}
                      {singleContact.type === "IG" && <IgIcon />}
                      {singleContact.type === "YT" && <YtIcon />}
                      {singleContact.type === "LN" && <LnIcon />}
                    </a>)}
                  </div>}
                  {museum.infoText && <div className="text-wrapper" dangerouslySetInnerHTML={{ __html: multiLanguageString(museum.infoText) }} />}
                </div>
              </Collapsible>
              <hr />
            </div>
            <div className="actions-wrapper">
              {museumLatestUserStation
                ? <Link to={`/station/${museumLatestUserStation}`}>
                  <div className="experience-link" style={{ backgroundImage: `url(${museum.experiencePhoto})` }}>
                    <div className="text">
                      <div className="top">
                        <div className="title">
                          {multiLanguageString(getString("museumGameCTA"))}
                        </div>
                        <div className="icon">
                          <LiveGoIcon />
                        </div>
                      </div>
                      {Object.keys(userFinishedStations).length > 0 && <div className="bottom">
                        <div className="bar">
                          <div className="partial" style={{ width: `${percent}%` }}></div>
                        </div>
                      </div>}
                    </div>
                  </div>
                </Link>
                : <div className="no-experience">
                  {multiLanguageString(getString("museumNoGame"))}
                </div>}
              <Link className="additionalAction" to={`/collection/museumItems/${museumId}`} state={{ prevRoute: location }}>{multiLanguageString(getString("collectionItemsTitle"))}<TrophyIcon /></Link>
              <a target="_blank" className="additionalAction" href={multiLanguageString(museum.ticketURL)}>{multiLanguageString(getString("museumTicketCTA"))}<TicketIcon /></a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
