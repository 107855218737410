import { DetailedHTMLProps, ReactNode, SelectHTMLAttributes, useState } from "react";
import { ReactComponent as SelectCaret } from "./select.svg";
import "./index.scss";

export const Select = ({ label, labelColor, textColor, activeColor, error, ...props }: { label?: ReactNode, labelColor?: string, textColor?: string, activeColor?: string, error?: string, } & DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>) => {
  const [focused, setFocused] = useState<boolean>(false);

  return <div className="Select">
    <label>
      {(label || error) && <span className="labels">
        <span className="label" style={{ color: labelColor }}>{label}</span>
        {error && <span className="error">{error}</span>}
      </span>}
      <span className="control" style={{ color: textColor }}>
        <select 
          onFocus={() => {setFocused(true);}}
          onBlur={() => {setFocused(false);}}
          style={{ color: textColor, outlineColor: focused ? activeColor : "none", boxShadow: focused ? `0px 0px 6px ${activeColor}` : "none" }} 
          {...props}
        />
        <SelectCaret className="caret" style={{ color: labelColor }} />
      </span>
    </label>
  </div>;
};
