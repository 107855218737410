import { Link } from "react-router-dom";

import { ReactComponent as NavCollectionIcon } from "./navCollection.svg";
import { ReactComponent as NavCollectionActiveIcon } from "./navCollectionActive.svg";
import { ReactComponent as NavMapIcon } from "./navMap.svg";
import { ReactComponent as NavMapActiveIcon } from "./navMapActive.svg";
import { ReactComponent as NavProfileIcon } from "./navProfile.svg";
import { ReactComponent as NavProfileActiveIcon } from "./navProfileActive.svg";

import "./index.scss";
import { LanguageContext } from "../../utils/multiLanguageString";
import { useContext } from "react";

export const BottomNavigation = ({ activeItem }: { activeItem: string }) => {
  const { multiLanguageString, getString } = useContext(LanguageContext);
  
  return <div className="BottomNavigation">
    <nav>
      <Link to="/" className={activeItem === "map" ? "active" : ""}>
        <div className="icon">
          {activeItem === "map" ? <NavMapActiveIcon /> : <NavMapIcon />}
        </div>
        <div className="text">
          {multiLanguageString(getString("navigationMap"))}
        </div>
      </Link>
      <Link to={"/collection"} className={activeItem === "collection" ? "active" : ""}>
        <div className="icon">
          {activeItem === "collection" ? <NavCollectionActiveIcon /> : <NavCollectionIcon />}
        </div>
        <div className="text">
          {multiLanguageString(getString("navigationCollection"))}
        </div>
      </Link>
      <Link to={"/profile"} className={activeItem === "profile" ? "active" : ""}>
        <div className="icon">
          {activeItem === "profile" ? <NavProfileActiveIcon /> : <NavProfileIcon />}
        </div>
        <div className="text">
          {multiLanguageString(getString("navigationProfile"))}
        </div>
      </Link>
    </nav>
  </div>;
};
