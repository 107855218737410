import { BottomNavigation } from "../../components/BottomNavigation";
import { ReactComponent as TrophyIcon } from "./trophy.svg";
import { ReactComponent as ShareIcon } from "./share.svg";
import { ReactComponent as CopyIcon } from "./copy.svg";

import "./index.scss";
import { LanguageContext } from "../../utils/multiLanguageString";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getArrayRange } from "../../utils/getArrayRange";
import { z } from "zod";
import { roomSchema } from "../../../../backend/types/room";
import { itemSchema } from "../../../../backend/types/item";
import { useContext, useEffect, useState } from "react";
import { ItemModal } from "../../components/ItemModal";
import { museumSchema } from "../../../../backend/types/museum";
import { makeRemoteCallHandler } from "../../libProcedureWire";
import { Endpoints } from "../../../../backend";
import { CustomButton } from "../../components/CustomAButton/CustomButton";
import { skinAssets, skinCeilings } from "../../utils/skins/skinAssets";
import { mediaSchema } from "../../../../backend/types/media";
import ga4 from "react-ga4";

export const Collection = (
  { room, items, medias, placeItem, unplaceItem, museums, currentUserId }: 
  { 
    room?: { items: z.infer<typeof roomSchema>["items"], skins: z.infer<typeof roomSchema>["skins"] }, 
    items: z.infer<typeof itemSchema>[],
    medias: z.infer<typeof mediaSchema>[],
    placeItem: ({ item, index }: { item: z.infer<typeof itemSchema>, index: number }) => void,
    unplaceItem: ({ item }: { item: z.infer<typeof itemSchema> }) => void,
    museums: z.infer<typeof museumSchema>[],
    currentUserId?: string,
  },
) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { userId } = useParams();

  const remoteCall = makeRemoteCallHandler<Endpoints>();

  const [currentRoomItems, setCurrentRoomItems] = useState(room?.items);
  const [currentRoomSkins, setCurrentRoomSkins] = useState(room?.skins);
  const [userName, setUserName] = useState("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (currentUserId) {
      ga4.send({ hitType: "pageview", page: "/collection", title: "collection" });
    } else {
      ga4.send({ hitType: "pageview", page: "/userCollection", title: "other-user-collection" });
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (userId) {
        try {
          const userRoom = await remoteCall("otherRoomRead", { userId });
          setCurrentRoomItems(userRoom.items);
          setCurrentRoomSkins(userRoom.skins);
          setUserName(userRoom.userGivenName);
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [userId]);

  useEffect(() => {
    if (room) {
      setCurrentRoomItems(room.items);
      setCurrentRoomSkins(room.skins);
    }
  }, [room]);

  const [addingItem, setAddingItem] = useState<boolean>(false);
  const [itemToBeAdded, setItemToBeAdded] = useState<z.infer<typeof itemSchema>>();
  const [openItem, setOpenItem] = useState<(typeof items)[number] | undefined>();
 
  useEffect(() => {
    if (location.state && location.state.addItem) {
      setAddingItem(true);

      const currentItemToBeAdded = items.find((singleItem) => singleItem._id === location.state.addItem);
      setItemToBeAdded(currentItemToBeAdded);
    } else {
      setAddingItem(false);
    }
  }, [location.state]);

  const exitAddingState = () => {
    navigate(".", { state: null });
  };

  const setOpenItemById = (id: string) => {
    const currentItem = items.find((singleItem) => singleItem._id === id);

    if (currentItem) setOpenItem(currentItem);
  };

  const closeItem = () => {
    setOpenItem(undefined);
  };

  const { multiLanguageString, getString } = useContext(LanguageContext);
  
  return <>
    <div className={`Collection ${addingItem ? "grayed" : ""} ${userId ? "otherUser" : ""}`}>
      {currentRoomItems && currentRoomSkins && <div className="room">
        {/* @ts-expect-error */}
        {!userId && <div className="padder" style={{ backgroundImage: skinCeilings[currentRoomSkins.wall] }}></div>}
        <div className="image">
          {
            (["wall", "floor", "furniture", "decoration"] as const)
              .map((layerName) => 
                <div 
                  className="layer"
                  key={layerName}
                  //@ts-expect-error
                  style={{ backgroundImage: `url("${skinAssets[layerName][currentRoomSkins[layerName]]}")` }}
                />)
          }
          {/* <div className="layer" style={{ backgroundImage: `url("${roomBg}")` }}></div> */}
          <div className="spots">
            {getArrayRange(0, 11).map((index) => {
              const spot = currentRoomItems.find((singleSpot) => singleSpot.index === index);
              const item = spot ? items.find((singleItem) => singleItem._id === spot.item) : undefined;

              return (<div key={index} className={`spot s${index}`}>
                {
                  !addingItem 
                    ? (
                      spot && item 
                        ? <button onClick={() => {if (!userId) setOpenItemById(item._id);}} className="item">
                          {item.image !== "" && <img alt={multiLanguageString(item.name)} src={item.image} />}
                        </button> 
                        : (userId ? null : <Link to="/collection/items" state={{ prevRoute: location, position: index }} className="hotspot" title={multiLanguageString(getString("collectionFillSpot"))}></Link>)
                    ) : (
                      <>
                        {
                          (spot && item)
                            && <div className="item">
                              {item.image !== "" && <img alt={multiLanguageString(item.name)} src={item.image} />}
                            </div> 
                        }
                        {(itemToBeAdded?.positions && itemToBeAdded?.positions.includes(index)) 
                          && <button 
                            title={multiLanguageString(getString("collectionFillThisSpot"))} 
                            onClick={() => {
                              placeItem({ item: itemToBeAdded, index });
                              exitAddingState();
                            }} 
                            className="hotspot inviting"
                          ></button>}
                      </>
                    )
                }
              </div>);
            })}
          </div>
        </div>
        {userId && userName && userName !== "" && <div className="user-explain">
          <p>{multiLanguageString(getString("collectionBelongs"))} {userName}!</p>
          <p>{multiLanguageString(getString("collectionVisitCTA"))} <a href="/">{multiLanguageString(getString("appName"))}</a>!</p>
        </div>}
      </div>}
      {!userId && <BottomNavigation activeItem="collection" />}
      {!addingItem && !userId && <Link className="additionalAction" to={"/collection/items"} state={{ prevRoute: location }}>{multiLanguageString(getString("collectionItemsTitle"))}<TrophyIcon /></Link>}
      {!addingItem && !userId && currentUserId && <CustomButton onClick={() => {
        ga4.event("collection_share");

        const sharingUrl = `${window.location.origin}/userCollection/${currentUserId}`;

        if (navigator.share) {
          navigator.share({
            url: sharingUrl,
            title: multiLanguageString(getString("collectionShareText")),
          });
        } else if (navigator.clipboard) {
          navigator.clipboard.writeText(multiLanguageString(getString("collectionShareText")) + " " + sharingUrl).then(() => {setCopied(true);});
        }
      }} className="shareAction" title={multiLanguageString(getString("collectionShare"))}>{typeof navigator.share !== "undefined" ? <ShareIcon /> : copied ? <><span>{multiLanguageString(getString("collectionCopiedURL"))}</span></> : <><CopyIcon /><span>{multiLanguageString(getString("collectionCopyURL"))}</span></>}</CustomButton>}
      {addingItem && <button onClick={() => {exitAddingState();}} className="additionalAction">{multiLanguageString(getString("collectionCancelPlacing"))}</button>}
    </div>
    {currentRoomItems && <ItemModal museums={museums} item={openItem} close={closeItem} {...{ room: currentRoomItems, medias, unplaceItem }} />}
  </>;
};
