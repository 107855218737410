import { z } from "zod";
import { useNavigate, useParams } from "react-router-dom";
import { onboardingStationSchema, stationSchema } from "../../../../backend/types/station";
import { useContext, useEffect, useState } from "react";
import { DialogueMoment } from "./DialogueMoment";
import { UnknownMoment } from "./UnknownMoment";
import { characterSchema } from "../../../../backend/types/character";
import { itemSchema } from "../../../../backend/types/item";
import { ItemGivenMoment } from "./ItemGivenMoment";
import { AdditionalMediaMoment } from "./AdditionalMediaMoment";
import { CustomButton } from "../../components/CustomAButton/CustomButton";
import { LanguageContext } from "../../utils/multiLanguageString";

import { ReactComponent as PinIcon } from "./pin.svg";

import "./index.scss";
import { ExitSvg } from "../../components/ExitSvg";
import { CameraSvg } from "../../components/CameraSvg";
import { BackSvg } from "../../components/BackSvg";
import { ImageTapGameMoment } from "./ImageTapGameMoment";
import { OrderingGameMoment } from "./OrderingGameMoment";
import { MultipleChoiceGameMoment } from "./MultipleChoiceGameMoment";
import { PartialQuestItemMoment } from "./PartialQuestItemMoment";
import { museumSchema } from "../../../../backend/types/museum";
import { ArrowSvg } from "../../components/ArrowSvg";
import { SkinGivenMoment } from "./SkinGivenMoment";
import { OnboardingName } from "../../onboardingStations";
import { makeRemoteCallHandler } from "../../libProcedureWire";
import { Endpoints } from "../../../../backend";
import { mediaSchema } from "../../../../backend/types/media";
import ga4 from "react-ga4";

import { ReactComponent as LoadingSvg } from "./loading.svg";


const remoteCall = makeRemoteCallHandler<Endpoints>();

export type MomentProps = {
  moment: z.infer<typeof stationSchema>["moments"][number],
  goForward: () => void, 
  goBack?: () => void,
  allowBack?: boolean,
  visible: boolean, 
  characters: z.infer<typeof characterSchema>[], 
  items: z.infer<typeof itemSchema>[],
  museums: z.infer<typeof museumSchema>[],
  medias: z.infer<typeof mediaSchema>[],
  exit: () => void
};

const Moment = (
  {
    moment, goForward, goBack, allowBack, visible, characters, exit, items, museums, medias,
  }: MomentProps,
) => {
  let MomentComponent: (props: MomentProps) => JSX.Element = UnknownMoment;

  switch (moment.type) {
    case "dialogue":
      MomentComponent = DialogueMoment;
      break;
    case "itemGiven":
      MomentComponent = ItemGivenMoment;
      break;
    case "skinGiven":
      MomentComponent = SkinGivenMoment;
      break;
    case "additionalMedia":
      MomentComponent = AdditionalMediaMoment;
      break;
    case "imageTapGame":
      MomentComponent = ImageTapGameMoment;
      break;
    case "orderingGame":
      MomentComponent = OrderingGameMoment;
      break;
    case "multipleChoiceGame":
      MomentComponent = MultipleChoiceGameMoment;
      break;
    case "partialQuestItem":
      MomentComponent = PartialQuestItemMoment;
      break;
  }
  
  return <>
    <MomentComponent  {...{ moment, goForward, goBack, allowBack, visible, characters, exit, items, museums, medias }} />
  </>;
};

export const Station = (
  { 
    stations, characters, items, museums, medias, onboardingStation, clearOnboarding,
  }: {
    stations?: z.infer<typeof stationSchema>[], characters: z.infer<typeof characterSchema>[], items: z.infer<typeof itemSchema>[], museums: z.infer<typeof museumSchema>[], medias: z.infer<typeof mediaSchema>[], onboardingStation?: z.infer<typeof stationSchema>, clearOnboarding?: (onboarding: OnboardingName) => void
  },
) => {
  const navigate = useNavigate();

  const params = useParams();
  const [stationId, setStationId] = useState<string>();

  useEffect(() => {
    setStationId(onboardingStation ? undefined : params.stationId);
  }, [params]);

  const [station, setStation] = useState<z.infer<typeof stationSchema> | undefined>();
  const [nextStation, setNextStation] = useState<z.infer<typeof stationSchema> | undefined>();
  const [stationFinished, setStationFinished] = useState<boolean>(false);
  const [momentIndex, setMomentIndex] = useState<number>(0);

  const [visible, setVisible] = useState<boolean>(false);
  const [softInvisible, setSoftInvisible] = useState<boolean>(false);

  const [currentMoment, setCurrentMoment] = useState<z.infer<typeof stationSchema>["moments"][number] | undefined>(station?.moments?.[0]);
  const [visiblyFinished, setVisiblyFinished] = useState<boolean>(stationFinished);

  useEffect(() => {
    if (stationId && stations) {
      const currentStation = stations.find((singleStation) => singleStation._id === stationId);
      const currentNextStation = stations.find((singleStation) => singleStation._id === currentStation?.nextStationId);
    
      setStation(currentStation);
      setMomentIndex(0);

      if (currentStation) {
        const userFinishedStations: { [stationId: string]: string } = JSON.parse(localStorage.getItem("userFinishedStations") || "{}");
        const currentStationFinished = !!userFinishedStations[currentStation._id] && (currentStation.final || ((new Date().getTime() - new Date(userFinishedStations[currentStation._id]).getTime()) < 12 * 60 * 60 * 1000));
        setStationFinished(currentStationFinished);  
      }

      const nextTimeout = setTimeout(() => {
        setNextStation(currentNextStation);
      }, 500);

      return () => {clearTimeout(nextTimeout);};
    } else if (onboardingStation) {
      setStation(onboardingStation);
      setNextStation(undefined);
      setVisiblyFinished(false);
      setMomentIndex(0);
      setStationFinished(false);
      setNextStation(undefined);
    }
  }, [stationId, onboardingStation, stations]);

  const goForward = () => setMomentIndex((previousMomentIndex) => {
    if (!station) return previousMomentIndex;

    if ((previousMomentIndex + 1) === station.moments.length) {
      if (stationId) {
        setStationFinished(true);

        let userFinishedStations: { [stationId: string]: string } = JSON.parse(localStorage.getItem("userFinishedStations") || "{}");
        userFinishedStations = { ...userFinishedStations, [station._id]: new Date().toISOString() };
        localStorage.setItem("userFinishedStations", JSON.stringify(userFinishedStations));
      } else {
        if (clearOnboarding) {
          setTimeout(() => {
            clearOnboarding((station as z.infer<typeof onboardingStationSchema>).nameId as OnboardingName);
          }, 500);
        }
      }

      return previousMomentIndex;
    }

    return previousMomentIndex + 1;
  });

  const goBack = () => setMomentIndex((previousMomentIndex) => {
    if (!station) return previousMomentIndex;

    if ((previousMomentIndex - 1) >= 0) {
      return previousMomentIndex - 1;
    }

    return previousMomentIndex;
  });

  const exit = () => {
    if (station && stationId && station.museumId) navigate(`/museum/${station.museumId}`);
  };

  const repeatStation = () => {
    if (!station || !stationId) return;
    
    const userFinishedStations: { [stationId: string]: string } = JSON.parse(localStorage.getItem("userFinishedStations") || "{}");
    delete userFinishedStations[station._id];
    localStorage.setItem("userFinishedStations", JSON.stringify(userFinishedStations));

    setStationFinished(false);
    setMomentIndex(0);
  };

  const repeatEntireExperience = () => {
    if (!station) return;
    if (!station.museumId) return;
    if (!stationId) return;
    if (!stations) return;

    const userFinishedStations: { [stationId: string]: string } = JSON.parse(localStorage.getItem("userFinishedStations") || "{}");
    const filteredStations = Object.keys(userFinishedStations).reduce((cumulatedStations, currentStationId) => {
      const currentStation = stations.find((singleStation) => singleStation._id === currentStationId);

      if (currentStation && currentStation.museumId && currentStation.museumId !== station.museumId) {
        return { ...cumulatedStations, [currentStationId]: userFinishedStations[currentStationId] };
      }

      return { ...cumulatedStations };
    }, {} as { [stationId: string]: string });
    localStorage.setItem("userFinishedStations", JSON.stringify(filteredStations));

    navigate(`/museum/${station.museumId}`);
  };

  useEffect(() => {
    if (station) {
      ga4.send({ hitType: "pageview", page: "/station", title: "station", station: `${museums.find((singleMuseum) => station.museumId === singleMuseum._id)?.analyticsName}-${station.analyticsName}` });
    }
  }, [station]);

  useEffect(() => {
    if (stationId) {
      setVisible(false);

      (async () => {
        try {
          if (stationFinished && station?.final && station.museumId) {
            await remoteCall("finishedMuseumSave", { finishedMuseum: station.museumId });
          }
        } catch (e) {}
      })();

      const finishedTimeout = setTimeout(() => {
        setVisiblyFinished(stationFinished);
      }, 500);
  
      const visibilityTimeout = setTimeout(() => {
        setVisible(true);
      }, 1000);
  
      return () => {
        clearTimeout(finishedTimeout);
        clearTimeout(visibilityTimeout);
      };
    }
  }, [stationFinished]);

  useEffect(() => {
    if (!station) return;
    
    if (
      momentIndex > 0 
      && station.moments[momentIndex - 1].type === station.moments[momentIndex].type 
      && station.moments[momentIndex - 1].type === "dialogue"
    ) {

      setSoftInvisible(true);

      const momentChangeTimeout = setTimeout(() => {
        setCurrentMoment(station.moments[momentIndex]);
      }, 300);
  
      const visibilityTimeout = setTimeout(() => {
        setSoftInvisible(false);
      }, 600);
  
      return () => {
        clearTimeout(momentChangeTimeout);
        clearTimeout(visibilityTimeout);
      };
    } else {
      setVisible(false);

      const momentChangeTimeout = setTimeout(() => {
        setCurrentMoment(station.moments[momentIndex]);
      }, 500);
  
      const visibilityTimeout = setTimeout(() => {
        setVisible(true);
      }, 1000);
  
      return () => {
        clearTimeout(momentChangeTimeout);
        clearTimeout(visibilityTimeout);
      };
    }
  }, [momentIndex, station]);

  const { multiLanguageString, getString } = useContext(LanguageContext);
  
  return <div className={`Station ${visible ? "visible" : "invisible"} ${softInvisible ? "softInvisible" : ""} ${!onboardingStation ? "" : "onboardingStation"}`}>
    <div className="wrapper">
      {!currentMoment && !visiblyFinished && <div><LoadingSvg style={{ width: "50px", height: "50px", position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} /></div>}
      {currentMoment && !visiblyFinished &&
            <Moment 
              moment={currentMoment}
              allowBack={
                momentIndex > 0 
                && station?.moments[momentIndex].type === "dialogue"
                && station?.moments[momentIndex - 1].type === "dialogue"
              }
              {...{ goForward, goBack, visible, characters, exit, items, museums, medias }} 
            />
      }
      {visiblyFinished && <div className={`Moment finish-screen ${visible ? "visible" : "invisible"}`}>
        <div className="exit-wrapper">
          <CustomButton onClick={exit} aria-label={multiLanguageString(getString("momentExit"))} postIcon={<ExitSvg />} />
        </div>
        <div className="bottom-wrapper">
          {
            station?.final && <>
              <div className="upper-wrapper">
                <div className="title-wrapper">
                  {multiLanguageString(getString("momentFinal"))}
                </div>
                <div className="text-wrapper">
                  {multiLanguageString(getString("momentFinalThanks"))}
                </div>
              </div>
              <div className="lower-wrapper">
                <div className="qr-action-wrapper">
                  <CustomButton onClick={() => {navigate("/collection");}} postIcon={<ArrowSvg />}>
                    {multiLanguageString(getString("momentContinue"))}
                  </CustomButton>
                </div>
                <div className="repeat-wrapper">
                  <CustomButton kind="text" onClick={repeatStation} preIcon={<BackSvg />}>
                    {multiLanguageString(getString("momentRepeatStation"))}
                  </CustomButton>
                  <CustomButton kind="text" onClick={repeatEntireExperience} preIcon={<BackSvg />}>
                    {multiLanguageString(getString("momentRepeatEntireExperience"))}
                  </CustomButton>
                </div>
              </div>
            </>
          }
          {
            !station?.final && <>
              <div className="upper-wrapper">
                {nextStation && nextStation.name && <>
                  <div className="text-wrapper">
                    {multiLanguageString(getString("momentContinuePath"))}
                  </div>
                  {nextStation.image && <div className="image-wrapper">
                    <img src={multiLanguageString(nextStation.image)} alt="" />
                  </div>}
                  <div className="title-wrapper">
                    {multiLanguageString(nextStation.name)}
                  </div>
                  {museums.find((singleMuseum) => singleMuseum._id === nextStation.museumId) && <div className="location-wrapper">
                    <PinIcon /> {multiLanguageString(museums.find((singleMuseum) => singleMuseum._id === nextStation.museumId)?.name)}
                  </div>}
                </>}
                {(!nextStation || !nextStation.name) && <>
            
                  <div className="title-wrapper">
                    {multiLanguageString(getString("momentContinuePathGeneric"))}
                  </div>
                  <div className="text-wrapper">
                    {multiLanguageString(getString("momentContinueFindNextStation"))}
                  </div>
                </>}
              </div>
              <div className="lower-wrapper">
                <div className="qr-action-wrapper">
                  <CustomButton onClick={() => {navigate("/qrScan", { replace: true });}} postIcon={<CameraSvg />}>
                    {multiLanguageString(getString("momentScanQR"))}
                  </CustomButton>
                </div>
                {(process.env.REACT_APP_ALLOW_NO_QR as string === "true") && <div className="">
                  <CustomButton kind="text" onClick={() => {navigate(`/station/${nextStation?._id}`, { replace: true });}}>
                    <span style={{ color: "red" }}>[SOLO PER TEST] Vai alla tappa senza scansionare QR code</span>
                  </CustomButton>
                </div>}
                <div className="repeat-wrapper">
                  <CustomButton kind="text" onClick={repeatStation} preIcon={<BackSvg />}>
                    {multiLanguageString(getString("momentRepeatStation"))}
                  </CustomButton>
                </div>
              </div>
            </>
          }
        </div>
      </div>}
    </div>
  </div>;
};
